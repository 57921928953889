.read_only_control {
    height: 100%;
    padding-top: 6px;
    line-height: 16px;
}

.extra_players {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
}

.extra_player {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 8px;
    border-radius: 3px;
    font-weight: 700;
    box-shadow: 0 2px 5px 0 rgba(213, 217, 217, 0.5);
    background-color: var(--tw-gray-100);
}

.extra_player:not(:first-child) {
    margin-top: 5px;
}

.text_option {
    padding: 8px;
}
