.main_content {
    width: 100%;
    max-width: 1600px;
    padding: 0 10px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    position: relative;
}

.full_width {
    max-width: initial;
}