.slide_in_content {
    min-height: calc(100dvh - 35px);
}

.player_header_container {
    margin-top: 5px;
}

.note_container:not(:last-child) {
    margin-bottom: 4px;
}

.note_content {
    flex-grow: 1;
}

.no_content_text {
    font-family: AtlasGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    margin-top: 10px;
    color: var(--tw-gray-400);
}

.interaction_container {
    font-family: AtlasGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    color: var(--tw-gray-800);
    text-align: left;
    padding: 10px 10px;
}

.control_group {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
