:root {
    --chart-axis-color: var(--tw-gray-500);
    --chart-text-color: var(--tw-gray-400);
}

.trend_chart svg {
    background-color: #fff;
}

.trend_chart.bordered svg {
    border-radius: 6px;
    box-shadow: 0 0.5px 2px 0.5px rgba(9, 30, 66, 0.25);
}

.chart_axis_decoration {
    stroke: var(--chart-axis-color);
    fill: var(--chart-axis-color);
    color: var(--chart-axis-color);
}
