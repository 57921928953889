.cap_hit_label {
    display: flex;
    align-items: center;
    padding-left: 2px;
}

.cap_hit_base_salary {
    color: var(--ch-blue-800);
}

.cap_hit_bonus {
    font-size: 10px;
    color: var(--tw-green-600);
}

.ahl_salary {
    font-size: 10px;
    color: var(--tw-slate-500);
}

.cap_hit_base_salary.off_cap {
    color: var(--ch-blue-300);
}

.cap_hit_bonus.off_cap {
    color: var(--tw-green-400);
}

.cap_hit_indicator {
    display: flex;
    align-items: center;
    flex-grow: 1;
}

.cap_hit_bar_container {
    flex-grow: 1;
    display: flex;
    align-items: center;
    width: calc(100% - 30px);
}

.cap_hit_bar {
    height: 8px;
}

.cap_hit_bar.base_salary {
    background-color: var(--ch-blue-800);
    border-radius: 3px;
}

.cap_hit_bar.base_salary.off_cap {
    background-color: var(--ch-blue-300);
}

.cap_hit_bar.base_salary.with_bonus,
.cap_hit_bar.base_salary.buried_cap_penalty {
    border-radius: 3px 0 0 3px;
}

.cap_hit_bar.base_salary.buried_cap {
    border-radius: 0 3px 3px 0;
}
.cap_hit_bar.base_salary.buried_cap.with_bonus {
    border-radius: 0 0 0 0;
}

.cap_hit_bar.bonus {
    border-radius: 0 3px 3px 0;
    background-color: var(--tw-green-600);
}

.cap_hit_bar.bonus.off_cap {
    background-color: var(--tw-green-400);
}

.dot_indicator {
    display: flex;
    align-items: center;
    margin-left: 2px;
}
