.selector_header {
    padding: 0.5rem 1rem 0 0.75rem;
    font-size: 1rem;
    font-weight: bold;
    color: var(--tw-gray-700);
    font-family: AtlasGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    text-align: left;
}

.chart_header {
    padding: 0.5rem 1rem 0 0.75rem;
    font-size: 0.875rem;
    font-weight: bold;
    color: var(--tw-gray-700);
    font-family: AtlasGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    text-align: left;
}

.all_charts_container {
    display: flex;
    align-items: top;
    width: 100%;
}

.chart_container {
    width: 100%;
}

.button_selector {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    padding: 10px;
    width: 100%;
}

.selector_button {
    background-color: #fff;
    border: 1px solid var(--tw-gray-300);
    border-radius: 4px;
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    font-weight: bold;
    color: var(--tw-gray-700);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    font-family: AtlasGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    margin-right: 8px;
}

.selector_button:hover {
    background-color: var(--tw-gray-200);
}

.selector_button.selected {
    background-color: var(--tw-gray-300);
    border: 1px solid var(--tw-gray-400);
    color: var(--tw-gray-800);
}

.selector_button.selected:hover {
    background-color: var(--tw-gray-300);
}

.selector_button.active {
    background-color: var(--tw-gray-300);
    border: 1px solid var(--tw-gray-400);
    color: var(--tw-gray-800);
}
