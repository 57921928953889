.vertical_slider_container {
    position: absolute;
    transform: translateY(50%);
    display: flex;
    align-items: center;
    height: fit-content;
}

.left {
    left: 10px;
}

.right {
    right: -11px;
}

.title {
    color: var(--tw-gray-400);
    font-size: 10px;
    width: 30px;
    text-align: left;
    padding-right: 3px;
}
