.layout_header {
    background: var(--tw-gray-950);
    z-index: 1;
    position: relative;
    top: 0px;
    left: 0px;
    display: flex;
    width: 100%;
}

.layout_header .button_spacer {
    margin: 0 50px;
}

.main_header {
    display: flex;
    overflow: hidden;
    width: 100%;
    padding: 10px 0;
    height: 80px;
    font-size: 36px;
}

.layout_header.narrow .button_spacer {
    margin: 0 35px;
}

.layout_header.narrow .main_header {
    padding: 3px 0;
    font-size: 26px;
    height: 50px;
}

.layout_header.wide .main_header {
    padding: 20px 0;
    height: 100px;
}

.spacer {
    width: 50px;
}

@media print {
    .spacer {
        width: 0;
    }
}
