.horizontal_bar_indicator {
    display: flex;
    align-items: center;
    flex-grow: 1;
    position: relative;
    justify-content: flex-end;
}

.horizontal_bar_container {
    flex-grow: 1;
    display: flex;
    align-items: center;
}

.horizontal_bar {
    height: 10px;
    border-radius: 4px;
    background-color: var(--tw-slate-400);
}

.horizontal_bar.single_end_rounding {
    border-radius: 0 4px 4px 0;
}

.horizontal_bar.single_end_rounding.flipped {
    border-radius: 4px 0 0 4px;
}

.inverse {
    background-color: var(--tw-slate-200);
}

.horizontal_bar_label {
    padding-left: 4px;
    min-width: 24px;
}

.horizontal_bar_label.flipped {
    padding-left: 0;
    padding-right: 4px;
}

.horizontal_bar.low_tier {
    background-color: var(--ch-red-500);
}
.horizontal_bar.inverse.low_tier {
    background-color: var(--ch-red-300);
}

.horizontal_bar.mid_tier {
    background-color: var(--tw-slate-400);
}
.horizontal_bar.inverse.mid_tier {
    background-color: var(--tw-slate-300);
}

.horizontal_bar.high_tier {
    background-color: var(--ch-blue-500);
}
.horizontal_bar.inverse.high_tier {
    background-color: var(--ch-blue-200);
}

.horizontal_bar.top_tier {
    background-color: var(--tw-green-500);
}
.horizontal_bar.inverse.top_tier {
    background-color: var(--tw-green-300);
}

.horizontal_bar.elite_tier {
    background-color: var(--tw-yellow-500);
}
.horizontal_bar.inverse.elite_tier {
    background-color: var(--tw-yellow-200);
}

.horizontal_bar.example_tier {
    background-color: var(--tw-gray-500);
}
.horizontal_bar.inverse.example_tier {
    background-color: var(--tw-gray-300);
}

.separator {
    width: 1px;
    height: 100%;
    background-color: var(--tw-gray-300);
    position: absolute;
    left: 50%;
}
