/* PlayerPage.module.css */
.header {
    display: flex;
    width: 100%;
}

.playerHeaderContainer {
    margin-top: 18px;
    margin-right: 10px;
    flex-grow: 1;
}
