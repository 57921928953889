.container {
    display: flex;
    flex-direction: column;
    font-size: 11px;
    color: var(--tw-gray-500);
    font-style: normal;
    font-family: AtlasGrotesk,"Helvetica Neue",Helvetica,Arial,sans-serif;
}

.horizontal {
    flex-direction: row;
}

.label {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 8px;
}

.radio {
    display: none;
}

.customRadioButton {
    height: 14px;
    width: 14px;
    border-radius: 50%;
    background-color: var(--tw-slate-350); /* Not selected color */
    margin-right: 8px;
    display: inline-block;
    transition: background-color 0.3s, box-shadow 0.3s;
}

.radio:checked + .customRadioButton {
    background-color: var(--ch-blue-400); /* Selected color */
    box-shadow: 0 1px 2px 0 rgba(9, 30, 66, 0.25);
}

.label:hover .customRadioButton:not(:checked) {
    background-color: var(--ch-blue-300); /* Hover color */
}
