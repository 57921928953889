.resultsList {
    width: 100%;
}

.search_result_item {
    padding: 8px 16px;
    color: var(--tw-slate-50);
    cursor: pointer;
    display: flex;
    font-size: 14px;
    font-family: AtlasGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-align: left;
}

.search_result_item.light_mode {
    background-color: var(--tw-slate-50);
    border-radius: 3px;
    margin-bottom: 2px;
}

.search_result_item:hover,
.search_result_item:focus {
    background-color: var(--tw-slate-700);
    outline: none; /* Remove the outline to use a custom focus style */
}

.search_result_item.light_mode:hover,
.search_result_item.light_mode:focus {
    background-color: var(--tw-slate-200);
    outline: none; /* Remove the outline to use a custom focus style */
}

.search_result_item.view_all {
    text-align: center;
    font-weight: bold;
}

/* Ensure the view-all item is visually distinct, e.g., with a border-top */
.search_result_item.view_all {
    border-top: 1px solid var(--tw-slate-600);
    color: var(--tw-slate-300);
}

.playerDetails {
    flex-grow: 1;
}

.playerName {
    color: var(--tw-slate-100);
    font-weight: bold;
    margin-bottom: 2px;
}

.light_mode .playerName {
    color: var(--tw-slate-800);
}

.playerBio {
    font-size: 11px;
}

.light_mode .playerBio {
    color: var(--tw-slate-800);
}

.iconDecorator {
    flex-grow: 0;
    width: 28px;
    overflow: hidden;
    position: relative;
    right: -5px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.iconDecorator img {
    height: 28px;
    object-fit: cover;
    opacity: 0.8;
}

.iconDecorator img.inactive {
    opacity: 0.4;
}
