.app_page {
    font-family: "acumin-pro-condensed", sans-serif;
    font-weight: 700;
    font-style: italic;
    color: #fff;
}

.loader_blind {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100dvh;
    z-index: 5;
    transition: background-color 0.125s;
    pointer-events: none;
}

.loader_blind.opaque {
    background-color: black;
}

.loader_blind.transparent {
    background-color: transparent;
}
