h2 {
    color: var(--tw-gray-600);
}

.group_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.playergroup {
    margin: 10px;
    width: fit-content;
}

.settings {
    display: flex;
}

.setting {
    margin: 5px 10px;
}

.setting_label {
    margin-bottom: 5px;
}

.board_container {
    opacity: 0;
    transition: opacity 0.5s;
}

.board_container.visible {
    opacity: 1;
}