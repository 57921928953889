.no_access {
    font-size: 12px;
    font-style: normal;
    font-family: AtlasGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: var(--tw-gray-600);
    margin-top: 20px;
    margin-bottom: 3px;
}

.development_priorities_container {
    font-size: 12px;
    font-style: normal;
    font-family: AtlasGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: var(--ch-blue-600);
    display: flex;
}

.development_priorities_tile_decoration {
    display: flex;
    align-items: center;
}

.development_priority_title {
    font-size: 12px;
    font-style: normal;
    font-family: AtlasGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: var(--tw-gray-600);
    display: none; /* Hide the title by default */
    margin-left: 6px;
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.development_priority {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.development_priority:hover .development_priority_title {
    display: initial;
    position: absolute;
    z-index: 1;
    top: -28px;
    background-color: white;
    padding: 4px;
    box-shadow: 0 1px 2px 0 rgba(9, 30, 66, 0.25);
    border-radius: 3px;
    overflow: visible;
}

.season_sessions_container {
    position: relative;
    top: 1px;
    margin-left: 6px;
}

.season_sessions_count {
    font-size: 12px;
    font-style: normal;
    font-family: AtlasGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.add_session_button {
    padding: 20px 10px 0 0;
}

.add_session_button.vertical {
    padding-top: 10px;
    padding-left: 10px;
}
