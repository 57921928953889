.indicator_label {
    color: var(--tw-gray-450);
    font-style: normal;
    font-family: AtlasGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 11px;
    font-weight: 700;
    margin-right: 1px;
}

.status_value_indicator .indicator_label {
    color: var(--tw-gray-400);
    margin-right: 4px;
    font-size: 10px;
}

.status_value_indicator.flipped .indicator_label {
    margin-right: 0;
    margin-left: 4px;
}

.status_value_indicator.large .indicator_label {
    color: var(--tw-gray-500);
    margin-right: 5px;
    font-size: 11px;
}

.inverse .indicator_label {
    color: var(--tw-gray-300);
}

.indicator_group .indicator_label {
    margin-right: 2px;
}

.low_tier,
.not_ok_status_level {
    color: var(--ch-red-500);
}
.single_indicator.low_tier,
.single_indicator.not_ok_status_level,
.numericbutton.low_tier {
    background-color: var(--ch-red-500);
}
div.consistency_indicator.low_tier {
    border: 2px solid var(--ch-red-500);
}

.inverse .low_tier {
    color: var(--ch-red-400);
}
.inverse .single_indicator.low_tier {
    background-color: var(--ch-red-400);
}
.inverse.numericbutton.low_tier {
    background-color: var(--ch-red-300);
}
.inverse div.consistency_indicator.low_tier {
    border: 2px solid var(--ch-red-400);
}

.mid_tier {
    color: var(--tw-slate-400);
}
.single_indicator.mid_tier,
.numericbutton.mid_tier {
    background-color: var(--tw-slate-400);
}
div.consistency_indicator.mid_tier {
    border: 2px solid var(--tw-slate-400);
}

.inverse .mid_tier {
    color: var(--tw-slate-200);
}
.inverse .single_indicator.mid_tier {
    background-color: var(--tw-slate-200);
}
.inverse.numericbutton.mid_tier {
    background-color: var(--tw-slate-300);
}
.inverse div.consistency_indicator.mid_tier {
    border: 2px solid var(--tw-slate-200);
}

.high_tier {
    color: var(--ch-blue-500);
}
.single_indicator.high_tier,
.numericbutton.high_tier {
    background-color: var(--ch-blue-500);
}
div.consistency_indicator.high_tier {
    border: 2px solid var(--ch-blue-500);
}

.inverse .high_tier {
    color: var(--ch-blue-300);
}
.inverse .single_indicator.high_tier {
    background-color: var(--ch-blue-300);
}
.inverse.numericbutton.high_tier {
    background-color: var(--ch-blue-200);
}
.inverse div.consistency_indicator.high_tier {
    border: 2px solid var(--ch-blue-300);
}

.top_tier,
.ok_status_level {
    color: var(--tw-green-500);
}
.single_indicator.top_tier,
.single_indicator.ok_status_level,
.numericbutton.top_tier {
    background-color: var(--tw-green-500);
}
div.consistency_indicator.top_tier {
    border: 2px solid var(--tw-green-500);
}

.inverse .top_tier {
    color: var(--tw-green-400);
}
.inverse .single_indicator.top_tier {
    background-color: var(--tw-green-400);
}
.inverse.numericbutton.top_tier {
    background-color: var(--tw-green-300);
}
.inverse div.consistency_indicator.top_tier {
    border: 2px solid var(--tw-green-400);
}

.elite_tier,
.warning_status_level {
    color: var(--tw-yellow-500);
}
.single_indicator.elite_tier,
.single_indicator.warning_status_level,
.numericbutton.elite_tier {
    background-color: var(--tw-yellow-500);
}
div.consistency_indicator.elite_tier {
    border: 2px solid var(--tw-yellow-500);
}

.inverse .elite_tier {
    color: var(--tw-yellow-400);
}
.inverse .single_indicator.elite_tier {
    background-color: var(--tw-yellow-400);
}
.inverse.numericbutton.elite_tier {
    background-color: var(--tw-yellow-200);
}
.inverse div.consistency_indicator.elite_tier {
    border: 2px solid var(--tw-yellow-400);
}

.example {
    color: var(--tw-slate-500);
}
.single_indicator.example,
.numericbutton.example {
    background-color: var(--tw-slate-500);
}
div.consistency_indicator.example {
    border: 2px solid var(--tw-slate-500);
}

.warning {
    color: var(--tw-orange-500);
}

.no_tier {
    color: var(--tw-slate-475);
}
.inverse .no_tier {
    color: var(--tw-slate-300);
}

div.indicator {
    display: flex;
    align-items: center;
}

.inner_indicator {
    margin-right: 2px;
}

.indicator.flipped {
    flex-direction: row-reverse;
}

div.indicator.numeric {
    margin-top: 2px;
}

.numericmetric {
    text-align: center;
}

.numericmetric.one_wide {
    width: 0.9em;
}

.numericmetric.oneplus_wide {
    width: 1em;
}

.numericmetric.two_wide {
    width: 1.2em;
}

.numericmetric.twoplus_wide {
    width: 1.6em;
}

.numericmetric.three_wide {
    width: 2.3em;
}

.numericmetric.threeplus_wide {
    width: 2.8em;
}

.numericmetric.four_wide {
    width: 3em;
}

div.indicator.numeric > .label {
    margin-right: 2px;
}

div.consistency_indicator {
    height: 18px;
    width: 18px;
    border-radius: 9px;
    border: 1px solid none;
    display: flex;
    align-items: center;
    justify-content: center;
}

div.indicator.indicator_group > div.consistency_indicator {
    padding-left: 3px;
    display: flex;
    width: 100%;
    align-items: center;
}

div.decorated_indicator > div.consistency_indicator {
    height: 14px;
    width: 14px;
    border-radius: 50%;
    display: flex;
    align-items: center;
}

div.single_indicator,
.dot_indicator,
.laterality_indicator {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
}

.laterality_indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--ch-blue-500);
    background-color: var(--tw-slate-425);
    color: var(--tw-gray-100);
    font-weight: 800;
    height: 17px;
    width: 17px;
    font-size: 12px;
    font-family: AtlasGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.dot_indicator.small_dot {
    height: 7px;
    width: 7px;
}

.nmc_clause {
    background: var(--ch-red-500);
}

.ntc_clause {
    background: var(--tw-orange-375);
}

.m_ntc_clause {
    background: var(--tw-yellow-425);
}

.bonus_extra_salary_type {
    background: var(--tw-green-600);
}

.bonus_non_roster_extra_salary_type {
    background: var(--tw-green-400);
}

.ahl_extra_salary_type {
    background: var(--tw-slate-425);
}

div.single_indicator.pill_layout {
    height: 10px;
    width: 22px;
    border-radius: 5px;
}

div.large div.single_indicator {
    height: 16px;
    width: 16px;
}

div.single_indicator.no_tier {
    height: 12px;
}

div.consistency_indicator.no_tier > div.single_indicator:not(.no_tier) {
    height: 13px;
    width: 13px;
}

div.decorated_indicator
    div.consistency_indicator:not(.no_tier)
    > div.single_indicator {
    height: 8px;
    width: 8px;
}

div.decorated_indicator .indicator_label {
    margin-right: 1px;
}

div.decorated_indicator {
    display: flex;
    align-items: center;
    position: relative;
    width: 26px;
}

div.decorated_indicator.consistency_indicator_example {
    margin-right: 0px;
}

.numericmetric {
    font-size: 13px;
    font-family: AtlasGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 700;
}

.decorator {
    font-size: 15px;
    font-family: "acumin-pro-condensed", sans-serif;
    font-weight: 700;
    font-style: italic;
    color: var(--orange-color-10);
    position: relative;
    top: -7px;
    left: -1px;
    height: 14px;
}

.inverse .decorator {
    color: var(--orange-color-60);
}

.numericbutton {
    width: 72px;
    height: 38px;
    display: flex;
    flex-direction: column;
    font-size: 15px;
    font-family: AtlasGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 700;
    border-radius: 6px;
    justify-content: center;
    color: var(--tw-gray-50);
    margin: 0 3px;
    text-align: center;
}

.numericbutton.tight {
    width: initial;
    padding: 0 5px;
}

.numericbutton.tight.no_fill {
    width: initial;
    padding: 0 1px;
}

.numericbuttonlabel {
    font-size: 10.5px;
    margin-bottom: 2px;
}

.numericbutton.small {
    font-size: 12px;
}

.numericbutton.small .numericbuttonlabel {
    font-size: 10px;
}

.numericbuttonmetric.big {
    font-size: 18px;
}

.tone_indicator {
    border-radius: 3px;
    padding: 2px 4px 1px 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 26px;
    min-height: 16px;
}

.tone_indicator.small {
    padding: 0;
    min-width: 0;
    min-height: 0;
    border-radius: 50%;
    height: 10px;
    width: 10px;
}

.negative_tone {
    background-color: var(--ch-red-500);
}
.positive_tone {
    background-color: var(--tw-green-500);
}
.neutral_tone {
    background-color: var(--tw-slate-400);
}

.tone_indicator_label {
    font-size: 10px;
    font-weight: bold;
    color: var(--tw-gray-100);
    font-family: AtlasGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
}
