.panel_container {
    position: fixed;
    right: 0;
    top: 0;
    width: 300px;
    height: 100dvh;
    background: none;
    transition: transform 0.3s ease-in-out;
    transform: translateX(-100%);
    z-index: 3;
    display: flex;
}

.panel_container.is_right:not(.open) {
    transform: translateX(100%);
}

.panel_container:not(.is_right) {
    flex-direction: row-reverse;
}

.panel_container.open {
    transform: translateX(0);
}

.panel_container:not(.is_wide) {
    flex-direction: column;
}

.panel {
    box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.3);
    background: white;
    padding: 10px;
    overflow: auto;
    cursor: pointer;
}

.button_container {
    height: 30px;
    box-shadow: -1px 0px 5px rgba(50, 50, 50, 0.5);
    background: var(--tw-slate-500);
    border-radius: 0 0 0 5px;
}

.close_button {
    position: relative;
    left: -1px;
    height: 30px;
    width: 30px;
    z-index: 10;
    background: none;
    border: none;
    font-size: 28px;
    font-family: Arial, Helvetica, sans-serif;
    color: var(--tw-gray-200);
    font-weight: 700;
    line-height: 30px;
    text-align: center;
}

.panel_container:not(.is_right) .button_container {
    border-radius: 0 0 5px 0;
    flex-direction: row-reverse;
}

.panel_container.is_right.is_wide.open .button_container {
    margin-left: -30px;
}

.panel_container:not(.is_wide) .button_container {
    width: 100%;
    background: none;
    border: none;
}

.panel_container:not(.is_wide) .panel {
    box-shadow: none;
    padding: 0 10px;
}

.panel_container:not(.is_wide) .close_button {
    color: var(--tw-gray-400);
    font-size: 28px;
}

.loader_blind {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    transition: background-color 0.125s;
    pointer-events: none;
}

.loader_blind.opaque {
    background-color: white;
}

.loader_blind.transparent {
    background-color: transparent;
}

.no_padding {
    padding: 0;
}

.bottom_spacer {
    height: 90px;
}



@media print {
    .panel {
        box-shadow: none;
    }
    .button_container {
        display: none;
    }
}