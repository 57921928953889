.tiles_section {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tile_container {
    width: 220px;
}

.tile_container > div {
    padding: 8px;
}
