.no_padding {
    padding: 0px;
}

:root {
    --nav-item-color: var(--tw-slate-300);
}

.nav_menu_panel {
    padding: 10px 24px 10px 24px;
    width: 100%;
    max-width: 100vw;
}

.nav_section_title,
.nav_subsection_title {
    margin-bottom: 2px;
    font-size: 24px;
    color: var(--tw-slate-200);
    font-style: normal;
    font-weight: 600;
    font-family: AtlasGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.nav_subsection_title {
    font-size: 16px;
    color: var(--tw-slate-400);
    font-weight: 600;
    margin: 12px 0 8px 0;
}

.nav_section_items_group {
    margin-top: 8px;
    margin-bottom: 20px;
    overflow: hidden;
}

.nav_subsection_items_group {
    background-color: var(--tw-slate-800);
    border-radius: 3px;
}

.nav_item {
    padding: 2px 0px 2px 2px;
}

.nav_item,
.nav_item a {
    text-decoration: none;
    font-weight: 700;
    font-size: 28px;
    font-family: "acumin-pro-condensed", sans-serif;
    color: var(--nav-item-color);
    opacity: 0.85;
    transition: 0.3s;
}

.nav_item a:hover {
    color: var(--tw-slate-50);
    opacity: 1;
}

.nav_item.selected {
    background-color: var(--tw-slate-500);
    color: var(--tw-slate-100);
    opacity: 1;
}

.search_form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    background-color: var(--tw-slate-800);
    border-radius: 3px;
}

.search_button {
    padding: 8px 12px;
    background-color: var(--tw-slate-700);
    border: none;
    color: var(--tw-slate-300);
    font-size: 10px;
    cursor: pointer;
}

.search_button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.search_button:hover:not(:disabled) {
    background-color: var(
        --tw-slate-600
    ); /* Lighter than the button's background for hover effect */
    border-color: var(--tw-slate-400);
}

.search_results_dropdown {
    width: 100%; /* Adjust width as needed */
    background: var(--tw-slate-800);
    border: 1px solid var(--tw-slate-700);
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional shadow for better separation from content */
    margin-bottom: 10px;
}
