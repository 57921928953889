.searchInputContainer {
    width: 100%;
    font-family: AtlasGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: var(--tw-gray-500);
}

.textInputContainer {
    display: flex;
}

.resultsListContainer {
    width: 100%;
    position: relative;
}

.resultsList {
    width: 100%;
    position: absolute;
    z-index: 1000;
    border-top: 1px solid var(--tw-gray-300);
    border-bottom: 1px solid var(--tw-gray-300);
}

.search_result_item {
    padding: 8px 16px;
    cursor: pointer;
    display: flex;
    text-align: left;
    background-color: white;
}

.search_result_item:hover,
.search_result_item:focus {
    background-color: var(--ch-blue-50);
    outline: none; /* Remove the outline to use a custom focus style */
}
.search_result_item.selected {
    background-color: var(--ch-blue-100);
}

.searchResultDetails {
    flex-grow: 1;
}

.searchResultLabel {
    color: var(--tw-gray-500);
    font-weight: bold;
    margin-bottom: 2px;
}
