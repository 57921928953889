.vertical_bar_indicator {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    position: relative;
}

.vertical_bar_container {
    flex-grow: 1;
    flex-direction: column-reverse;
    display: flex;
    align-items: center;
    height: calc(100% - 20px);
}

.vertical_bar {
    width: 18px;
    border-radius: 4px;
    background-color: var(--tw-slate-400);
    transition: height 0.3s ease;
}

.vertical_bar.single_end_rounding {
    border-radius: 4px 4px 0 0;
}

.vertical_bar.single_end_rounding.flipped {
    border-radius: 0 0 4px 4px;
}

.inverse {
    background-color: var(--tw-slate-200);
}

.vertical_bar_label {
    padding-bottom: 4px;
}

.vertical_bar_label.flipped {
    padding-bottom: 0;
    padding-top: 4px;
}

.vertical_bar_label_percentage {
    font-size: 8px;
}

.vertical_bar.low_tier {
    background-color: var(--ch-red-500);
}
.vertical_bar.inverse.low_tier {
    background-color: var(--ch-red-300);
}

.vertical_bar.mid_tier {
    background-color: var(--tw-slate-400);
}
.vertical_bar.inverse.mid_tier {
    background-color: var(--tw-slate-300);
}

.vertical_bar.high_tier {
    background-color: var(--ch-blue-500);
}
.vertical_bar.inverse.high_tier {
    background-color: var(--ch-blue-200);
}

.vertical_bar.top_tier {
    background-color: var(--tw-green-500);
}
.vertical_bar.inverse.top_tier {
    background-color: var(--tw-green-300);
}

.vertical_bar.elite_tier {
    background-color: var(--tw-yellow-500);
}
.vertical_bar.inverse.elite_tier {
    background-color: var(--tw-yellow-200);
}

.vertical_bar.example_tier {
    background-color: var(--tw-gray-500);
}
.vertical_bar.inverse.example_tier {
    background-color: var(--tw-gray-300);
}

.separator {
    width: 36px;
    height: 1px;
    background-color: var(--tw-gray-300);
    top: 50%;
}
