.no_access {
    font-size: 12px;
    font-style: normal;
    font-family: AtlasGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: var(--tw-gray-600);
    margin-top: 20px;
    margin-bottom: 3px;
}

.recent_interactions_container {
    color: var(--ch-blue-600);
    display: flex;
}

.most_recent_interactions_container {
    display: flex;
    align-items: center;
    margin-left: 8px;
}

.recent_interactions_container > :not(:first-child),
.most_recent_interactions_container > :not(:first-child) {
    margin-left: 2px;
}

.interactions_tile_decoration {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-style: normal;
    font-family: AtlasGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.most_recent_interaction_label {
    font-size: 10px;
    margin-left: 2px;
    color: var(--tw-slate-375);
    min-width: 24px;
    text-align: left;
}

.recent_interaction_tooltip {
    color: var(--tw-gray-600);
    display: none; /* Hide the title by default */
    margin-left: 6px;
    max-width: 420px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.recent_interaction {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.recent_interaction:hover .recent_interaction_tooltip {
    display: initial;
    position: absolute;
    z-index: 1;
    top: -28px;
    background-color: white;
    padding: 4px;
    box-shadow: 0 1px 2px 0 rgba(9, 30, 66, 0.25);
    border-radius: 3px;
    overflow: visible;
}

.season_interactions_container {
    position: relative;
    top: 1px;
}

.season_interactions_count {
    font-size: 14px;
    min-width: 12px;
}

.add_interaction_button {
    padding: 20px 10px 0 0;
}

.add_interaction_button.vertical {
    padding-top: 10px;
    padding-left: 10px;
}

.sort_option {
    padding: 6px;
}

.setting_label {
    margin-right: 6px;
    color: var(--tw-gray-400);
}