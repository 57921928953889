.player_development_priority_container {
    font-family: AtlasGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    color: var(--tw-gray-800);
    text-align: left;
    border: 1px solid var(--tw-gray-200);
    border-radius: 3px;
    box-shadow: 0 0.5px 0.5px 0px rgba(9, 30, 66, 0.25);
    padding: 10px 10px 8px 10px;
}

.player_development_priority_container:not(:last-child) {
    margin-bottom: 4px;
}

.player_development_priority_summary {
    display: flex;
}

.player_development_priority_title {
    font-weight: bold;
    color: var(--tw-gray-500);
}
.player_development_priority_title {
    font-size: 16px;
}

.player_development_priority_stats {
    font-size: 10px;
    font-style: italic;
    font-weight: normal;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    margin-right: 8px;
}

.player_development_priority_stat_line:not(:first-child) {
    margin-top: 2px;
}

.player_development_priority_content {
    display: flex;
    justify-content: space-between;
}

.player_development_priority_details {
    flex-grow: 1;
}

.player_development_priority_text {
    min-height: 24px;
    font-size: 14px;
    margin: 10px 12px 10px 0;
    display: flex;
    align-items: center;
}

.player_development_priority_controls_container {
    margin-left: 6px;
    margin-top: 8px;
    display: flex;
    justify-content: space-between;
}

.controls_group {
    display: flex;
    align-items: center;
}

.control:not(:last-child) {
    margin-right: 8px;
}

.player_development_priority_header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: var(--tw-slate-400);
    font-weight: 700;
    margin-bottom: 14px;
}

.player_development_priority_chart_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
