.scouting_report_wrapper {
    position: relative;
    transition: opacity 0.3s;
    height: 100%;
}

.scouting_report {
    font-family: AtlasGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-size: 12px;
    overflow-y: auto;
    height: 100%;
    transition: 0.3s;
    opacity: 1;
}

.scouting_report.not_visible {
    opacity: 0;
}

.scouting_report_header {
    padding: 5px 0 8px 5px;
    text-align: left;
}

.ratings_group {
    display: flex;
    margin-top: 5px;
}

.ratings_section.player_ratings {
    flex: 3;
}
.ratings_section.skill_ratings {
    flex: 2;
}

.ratings_section_header {
    font-size: 10px;
    margin-bottom: 4px;
    text-align: left;
    padding-left: 5px;
}

.rating_item {
    display: flex;
    padding: 1px 5px;
    font-size: 10px;
    font-style: normal;
    max-width: 200px;
    margin: auto;
}

.rating_label {
    text-align: left;
    flex: auto;
    font-weight: normal;
}

.rating_value {
    text-align: right;
    flex: auto;
}

.scouting_report_notes,
.scouting_report_scout,
.rating {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    padding: 0 5px;
}

.scouting_report_notes {
    font-family: "Roboto", sans-serif;
}

.scouting_report_notes,
.rating {
    text-align: left;
}

.scouting_report_scout {
    text-align: right;
}

.rating,
.scouting_report_scout {
    margin: 0;
}

.navigate_button {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 56px;
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    opacity: 0;
    cursor: pointer;
    transition: opacity 0.3s;
}

.scouting_report_wrapper:hover .navigate_button {
    opacity: 0.55;
}

.prev_button {
    left: 0;
}

.next_button {
    right: 0;
}

.no_data {
    margin-top: 5px;
    margin-bottom: 3px;
    font-size: 12px;
    font-style: normal;
    font-family: AtlasGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
}
