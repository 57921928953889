.depth_chart_container {
    width: 100%;
}

.tier_section {
    display: flex;
}

.tier_section_header {
    width: 100px;
    padding: 15px 10px 15px 10px;
    font-size: 20px;
    display: flex;
    flex-direction: column;
}

.tier_section_header > div {
    text-align: center;
    width: 100%;
}

.tier_section_header_label {
    font-size: 15px;
    font-style: normal;
    color: var(--tw-gray-500);
    font-family: AtlasGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.tier_section_content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin: 0;
    padding: 0;
    width: 100%;
    background: var(--tw-gray-200);
}

img.tier_logo {
    max-width: 60px;
}

div.ahl_logo {
    margin-top: 10px;
}

.ahl_team_name {
    font-size: 15px;
    font-style: normal;
    color: var(--tw-gray-500);
    font-family: AtlasGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
    line-height: 1.15;
    margin-top: 3px;
}
.ahl_team_name_small {
    font-size: 11px;
}

.count_container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.man_games_lost_container {
    margin-top: 10px;
}

.count_label {
    font-size: 14px;
    font-style: normal;
    color: var(--tw-gray-500);
    font-family: AtlasGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin-top: 4px;
}

.count {
    margin-top: -12px;
}

span.count_value {
    font-size: 28px;
}

span.count_max {
    font-size: 26px;
    color: var(--tw-gray-500);
}

.count_warning {
    color: var(--tw-red-500);
}

.setting_label {
    margin-top: 4px;
    margin-bottom: 2px;
    font-size: 14px;
}

.setting {
    margin-top: 8px;
    font-size: 14px;
}

.narrow_selector {
    display: none;
    font-size: 11px;
    font-style: normal;
    color: var(--tw-gray-500);
    font-family: AtlasGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.page_class_option {
    padding: 6px;
}

.short_label {
    display: none;
}

@media (max-width: 1005px) {
    .tier_section {
        flex-direction: column;
    }
    .tier_section_header {
        flex-direction: row;
        align-items: center;
        padding: 5px 10px 0px 10px;
        width: auto;
        justify-content: flex-start;
        text-align: left;
    }
    .tier_section_header > div {
        text-align: left;
        width: auto;
        margin-right: 10px;
    }
    .tier_logo {
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1; /* Reduce line height */
    }
    img.tier_logo {
        max-height: 40px;
        margin-right: 10px;
    }
    .ahl_team_name_small {
        font-size: 15px;
    }
    div.ahl_logo {
        margin-top: initial;
    }
    .wide_selector {
        display: none;
    }
    .narrow_selector {
        display: initial;
    }
    .count_label {
        font-size: 10px;
    }
    span.count_value {
        font-size: 20px;
    }

    span.count_max {
        font-size: 18px;
    }
    .count {
        margin-top: -8px;
    }
    .setting {
        margin-top: 0px;
    }
}

@media (max-width: 450px) {
    .full_label {
        display: none;
    }
    .short_label {
        display: initial;
    }
}

@media (max-width: 415px) {
    span.count_max {
        display: none;
    }
    .count_label {
        font-size: 8px;
    }
    .tier_section_header > div {
        margin-right: 6px;
    }
}

@media print {
    .depth_chart_container {
        width: 100%;
        margin-top: 32px;
        padding: 0;
    }
    .setting {
        display: none;
    }
    .tier_section {
        break-inside: avoid; /* Avoids splitting within the element */
    }
}
