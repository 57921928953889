.nav_buttons_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 50px;
}

.nav_menu_open_button_container {
    flex-grow: 0;
}

.back_link_button_container {
    flex-grow: 0;
}
