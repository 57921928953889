.multiselect_control {
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.multiselect_item {
    position: relative;
    cursor: pointer;
    padding: 8px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-radius: 3px;
    font-weight: 700;
    box-shadow: rgba(213, 217, 217, 0.5) 0 2px 5px 0;
    background-color: var(--tw-gray-100);
}

.multiselect_item:not(:first-child) {
    margin-top: 5px;
}

.multiselect_item_checkbox_container {
    display: flex;
    align-items: center;
}

.multiselect_item_checkbox {
    height: 16px;
    width: 16px;
    padding-left: 4px;
}

.check {
    font-size: 16px;
    color: white;
    background-color: var(--tw-green-400);
    border-radius: 3px;
}

.multiselect_item.selected {
    background-color: var(--tw-gray-250);
    color: var(--tw-gray-500);
}

.multiselect_item:hover,
.multiselect_item:focus {
    background-color: var(--ch-blue-50);
    outline: none;
}
