.searchPageContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px;
}

.searchForm {
    display: flex;
    gap: 8px;
    margin-bottom: 16px;
    width: 100%;
    max-width: 400px;
}

.searchInput {
    flex-grow: 1;
    padding: 8px;
    border-radius: 10px;
    border: 1px solid var(--tw-gray-400);
}

.searchButton {
    padding: 4px 10px 7px 10px;
    border-radius: 10px;
    border: 1px solid var(--tw-gray-600);
    font-size: 14px;
    font-family: "acumin-pro-condensed", sans-serif;
    font-weight: 700;
    font-style: italic;
    width: 60px;
}

.searchButton:disabled {
    border: 1px solid var(--tw-gray-400);
}

.results_container {
    width: 100%;
    max-width: 400px;
}
