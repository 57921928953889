.responsive_container {
    display: flex;
    width: 100%;
    /* Default styles that apply to both layouts */
}

.horizontal {
    flex-direction: row;
    /* Specific styles for horizontal layout */
}

.vertical {
    flex-direction: column;
    /* Specific styles for vertical layout */
}
