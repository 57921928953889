.team_tiers {
    width: 100%;
}

.position_chart_layout {
    flex-grow: 1;
    width: 100%;
    max-width: 500px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.position_chart_container {
    color: var(--tw-gray-600);
    font-weight: bold;
    border-radius: 5px;
    border: 1px solid var(--tw-gray-200);
    display: flex;
    flex-direction: column;
}

.position_chart_player_container {
    background: white;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 8px;
    align-items: center;
    border-radius: 3px;
}

.position_chart_player_details {
    font-family: AtlasGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
}

.position_chart_player_details.narrow {
    flex-grow: 0;
    width: 170px;
}

.ahl_contract {
    background-color: var(--tw-orange-100);
}

.position_chart_player_container.clickable {
    box-shadow: 0 0.5px 2px 0.5px rgba(9, 30, 66, 0.25);
    margin-top: 4px;
}

.position_chart_player_container.clickable:hover {
    box-shadow: 0px 0px 1px 1px rgba(5, 226, 255, 0.25);
    transition: 0.1s;
}

.position_chart_player_container.clickable:active {
    background-color: var(--ch-blue-100) !important;
}

.position_chart_player_name {
    text-align: left;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
}

.position_chart_player_index {
    font-family: AtlasGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 600;
    text-align: left;
    font-size: 14px;
    position: relative;
    min-height: 15px;
    flex-grow: 1;
}

/* Flex container */
.all_positions_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.tier_label {
    font-size: 16px;
    margin-top: 15px;
    margin-bottom: 2px;
    color: var(--tw-gray-500);
    opacity: 0.85;
    font-style: normal;
    font-family: AtlasGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.section_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 500px;
}

.position_chart_player_container.position_chart_header {
    background: none;
    border: none;
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 16px;
}

.no_players {
    margin-top: 5px;
    margin-bottom: 3px;
    font-size: 12px;
    font-style: normal;
    font-family: AtlasGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: var(--tw-gray-400);
}

/* When the viewport is 1080px or more (500px * 2 + 1em * 2 * 2 for margins) */
.all_positions_container.wide {
    display: grid;
    grid-template-columns: 1fr 1fr; /* two columns of equal width */
    grid-column-gap: 20px;
    grid-template-rows: auto 1fr;
    align-items: start;
    justify-content: center;
    width: 100%;
}

.all_positions_container.wide > :first-child {
    margin-left: auto;
}

.all_positions_container.wide > .pos_F {
    grid-column: 1;
    grid-row: 1 / span 2; /* spans both rows */
}

.all_positions_container.wide > .pos_D {
    grid-column: 2;
    grid-row: 1; /* explicitly set to first row */
}

.all_positions_container.wide > .pos_G {
    grid-column: 2;
    grid-row: 2; /* explicitly set to second row */
}

.namedecoration {
    font-style: italic;
    font-weight: bold;
    color: var(--tw-gray-500);
    font-size: 10px;
    align-items: flex-end;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin-right: 8px;
}

.games_indicator {
    font-size: 9px;
    color: var(--tw-gray-500);
    font-style: normal;
}

.partial_coverage {
    color: var(--tw-orange-500);
}
