.team_news_container {
    width: 100%;
    max-width: 1100px;
    display: flex;
    flex-direction: column;
}

.team_news_content {
    background-color: white;
    border-radius: 3px;
    padding: 10px 0;
    width: 100%;
    margin-top: 10px;
}
