.container {
    position: relative;
}

.helpButton {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 700;
    font-size: 36px;
    border: none;
    background-color: var(--ch-blue-600);
    color: white;
    cursor: pointer;
    text-align: center;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media print {
    .helpButton {
        display: none;
    }
}