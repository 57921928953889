:global(:root) {
    --cap-view-h-padding: 16px;
    --section-title-xtra-padding: 0px;
    --player-details-width: 180px;
    --cell-width: 72px;
    --cell-margin: 6px;
    --perc-width: 30px;
    --row-h-padding: 4px;
    --total-cells: 10;
    --visible-cells: 10;
    /* Players can have 1 current year, 8 year extension, plus 1 free agency cell, to total 10 */
    --timeline-width: calc(
        (var(--cell-width) + var(--cell-margin)) * var(--total-cells) +
            var(--perc-width) + var(--row-h-padding)
    );
    --timeline-visible-width: calc(
        (var(--cell-width) + var(--cell-margin)) * var(--visible-cells) +
            var(--perc-width) + var(--row-h-padding)
    );
    /* Not sure yet where that 32px comes from, but it's needed to avoid scrolling by default */
    --timeline-section-width: calc(var(--timeline-visible-width));
    --cap-view-width: calc(
        var(--timeline-section-width) + var(--player-details-width) +
            var(--cap-view-h-padding) * 2
    );
}

.cap_report_header {
    display: flex;
    align-items: center;
    padding: 20px 0 10px 0;
}

.cap_report_header > :not(:first-child) {
    margin-left: 24px;
}

.cap_report_projection_metrics,
.cap_report_ltc_charts {
    display: flex;
}

.cap_report_projection_metrics {
    width: 100%;
    padding-left: 20px;
}

.cap_report_projection_metrics > :not(:first-child) {
    margin-left: 24px;
}

.cap_projection_metric {
    color: var(--tw-gray-500);
    font-style: normal;
    font-family: AtlasGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 160px;
}

.cap_projection_metric_label {
    font-size: 18px;
    font-weight: bold;
    padding-bottom: 6px;
}

.cap_projection_metric_value {
    font-size: 28px;
    font-weight: bold;
    padding-bottom: 4px;
    color: var(--tw-slate-450);
}

.cap_projection_metric_projection {
    display: flex;
    font-size: 14px;
}

.cap_projection_metric_projection_label {
    margin-right: 4px;
    color: var(--tw-gray-400);
}

.cap_projection_metric_projection_value {
    color: var(--tw-slate-400);
}

.positive {
    color: var(--tw-green-500);
}

.negative {
    color: var(--ch-red-500);
}

.cap_report_ltc_charts {
    width: 100%;
    justify-content: flex-end;
}

.cap_report_ltc_charts > :not(:first-child) {
    margin-left: 8px;
}

.team_long_term_commitment_chart_label {
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    color: var(--tw-gray-500);
    padding-bottom: 6px;
    text-align: left;
    font-family: AtlasGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.cap_report_container {
    display: flex;
    flex-direction: column;
    width: calc(var(--cap-view-width) + 8px);
}

.cap_report_section {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.cap_report_section_header {
    font-size: 18px;
    padding: 12px
        calc(var(--cap-view-h-padding) + var(--section-title-xtra-padding));
    font-style: normal;
    font-family: AtlasGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.cap_report_section_header > div {
    color: var(--tw-gray-500);
    width: 100%;
    text-align: left;
}

.cap_report_section_content {
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: white;
    border-radius: 3px;
    font-style: normal;
    font-family: AtlasGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: normal;
}

.cap_report_title {
    text-align: left;
    width: 100%;
    font-size: 24px;
    font-weight: bold;
    color: var(--tw-gray-500);
    padding: 8px;
}

.cap_report_content {
    width: 100%;
}

.cap_report_content > div:first-child {
    margin-top: 0;
}

.cap_view {
    width: 100%;
    padding: 0 var(--cap-view-h-padding);
    display: flex;
}

.cap_view_section_tier_column {
    display: flex;
    flex-direction: column;
}

.timeline_section_tier_column {
    width: var(--timeline-width);
    flex-grow: 0 0 var(--timeline-width);
}

.cap_view_column_players_details,
.cap_view_column_players_timeline {
    display: flex;
    flex-direction: column;
}

.cap_view_column_players_details {
    width: var(--player-details-width);
    flex-grow: 0 0 var(--player-details-width);
}

.cap_view_column_players_timeline {
    overflow-x: auto;
    white-space: nowrap;
    width: var(--timeline-section-width);
    flex-grow: 0 0 var(--timeline-section-width);
}

.cap_view_section_tier_column:not(:first-child) {
    margin-top: 24px;
}

.cap_view_section_tier_column:last-child {
    margin-bottom: 12px;
}

.cap_view_section_tier_header,
.cap_view_section_tier_footer {
    text-align: left;
}

.cap_view_header_data_column {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.cap_view_section_tier_header,
.cap_view_section_tier_footer {
    padding-left: 4px;
    font-size: 14px;
    font-weight: bold;
}

.cap_view_section_tier_header {
    color: white;
}

.cap_view_section_tier_footer {
    font-size: 12px;
    color: var(--tw-gray-400);
}

.cap_view_section_content {
    display: flex;
    flex-direction: column;
}

.cap_view_header_row,
.cap_view_footer_row,
.cap_view_player_row {
    display: flex;
    flex-direction: row;
}

.cap_view_header_row {
    height: 24px;
    padding-bottom: 4px;
    margin-bottom: 6px;
    background-color: var(--tw-gray-500);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.cap_view_header_row.tall {
    height: 40px;
}

.cap_view_footer_row {
    margin-top: 2px;
    padding-top: 4px;
    border-top: 1px solid var(--tw-gray-300);
}

.cap_view_player_row {
    align-items: center;
    padding: 4px var(--row-h-padding) 2px 0;
    height: 45px;
}

.cap_view_player_row.inactive {
    height: 30px;
}

.cap_view_header_data_columns,
.cap_view_footer_data_columns,
.player_cap_timeline {
    display: flex;
}

.cap_view_header_data_columns,
.cap_view_footer_data_columns {
    color: white;
    font-size: 12px;
    font-weight: 700;
}

.cap_view_footer_data_columns {
    color: var(--tw-gray-400);
}

.alt_row {
    background-color: var(--tw-gray-150);
}

.player_cap_report_header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    white-space: nowrap;
    padding-left: 4px;
}

.player_details_name {
    font-size: 14px;
    font-weight: bold;
    color: var(--tw-gray-500);
}

.player_cap_timeline {
    font-size: 12px;
    font-weight: normal;
    color: var(--tw-gray-600);
}

.player_info {
    display: flex;
    padding: 2px 0 1px 0;
    align-items: center;
    height: 18px;
    color: var(--tw-gray-450);
}

.player_info > :not(:first-child) {
    margin-left: 4px;
}

.player_info_text {
    font-weight: bold;
    font-size: 10px;
    width: 48px;
    text-align: left;
}

.cap_view_cap_season_column,
.cap_view_cap_perc_column {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.cap_view_cap_perc_column {
    width: var(--perc-width);
}

.cap_view_cap_season_column {
    position: relative;
    margin-left: var(--cell-margin);
    width: var(--cell-width);
    flex: 0 0 var(--cell-width);
    text-align: right;
}

.cap_view_free_agency_column {
    margin-left: var(--cell-margin);
    padding-left: 4px;
    width: var(--cell-width);
    flex: 0 0 var(--cell-width);
    text-align: center;
    display: flex;
    justify-content: center;
}

.free_agency_pill_container {
    width: 42px;
}

.clauses_indicators {
    position: absolute;
    margin-right: 1px;
    right: 0;
    bottom: -9px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.clauses_indicators > :not(:first-child) {
    margin-left: 2px;
}

@media (max-width: 1048px) {
    :global(:root) {
        --visible-cells: 9;
    }
    .cap_report_projection_metrics {
        flex-direction: column;
    }
    .cap_report_projection_metrics > :not(:first-child) {
        margin-left: initial;
        margin-top: 20px;
    }
}

@media (max-width: 970px) {
    :global(:root) {
        --visible-cells: 8;
    }
}

@media (max-width: 892px) {
    :global(:root) {
        --visible-cells: 7;
    }
    .cap_report_header {
        flex-direction: column;
    }
    .cap_report_header > :not(:first-child) {
        margin-left: initial;
        margin-top: 20px;
    }

    .cap_report_projection_metrics {
        flex-direction: row;
        padding-left: initial;
    }
    .cap_report_projection_metrics > :not(:first-child) {
        margin-left: 24px;
        margin-top: initial;
    }

    .cap_report_ltc_charts {
        justify-content: flex-start;
    }
}

@media (max-width: 814px) {
    :global(:root) {
        --visible-cells: 6;
    }
}

@media (max-width: 736px) {
    :global(:root) {
        --visible-cells: 5;
    }
}

@media (max-width: 658px) {
    :global(:root) {
        --visible-cells: 4;
    }
    .cap_report_projection_metrics {
        padding-left: 20px;
    }
    .cap_report_ltc_charts {
        display: none;
    }
}

@media (max-width: 580px) {
    :global(:root) {
        --visible-cells: 3;
    }
}

@media (max-width: 502px) {
    :global(:root) {
        --visible-cells: 2;
        --cap-view-h-padding: 0px;
        --section-title-xtra-padding: 4px;
    }
    .cap_report_title {
        padding: 8px 8px 0 8px;
    }
    .cap_report_projection_metrics {
        padding-left: 8px;
        justify-content: space-between;
    }
}

@media (max-width: 376px) {
    :global(:root) {
        --visible-cells: 1;
    }
    .cap_report_projection_metrics {
        flex-direction: column;
    }
    .cap_report_projection_metrics > :not(:first-child) {
        margin-left: initial;
        margin-top: 20px;
    }
}
