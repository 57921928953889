/* styles.module.css */
/* Get more blends at https://meyerweb.com/eric/tools/color-blend/ */

:global(:root) {
    --ch-red-50: #fef4f2;
    --ch-red-100: #fee6e5;
    --ch-red-200: #fcd0cf;
    --ch-red-225: #fcc6c5;
    --ch-red-250: #fbbcbb;
    --ch-red-275: #fbb2b1;
    --ch-red-300: #faa8a7;
    --ch-red-325: #f99c9c;
    --ch-red-350: #f88f90;
    --ch-red-375: #f78385;
    --ch-red-400: #f67679;
    --ch-red-425: #f46a6e;
    --ch-red-450: #f25e63;
    --ch-red-475: #ef5258;
    --ch-red-500: #ed464d;
    --ch-red-600: #da2435;
    --ch-red-700: #b8182b;
    --ch-red-800: #a6192e;
    --ch-red-900: #84172b;
    --ch-red-950: #490812;
    --ch-red: var(--ch-red-800);

    --ch-blue-50: #e4f7ff;
    --ch-blue-100: #cfefff;
    --ch-blue-200: #a8dfff;
    --ch-blue-225: #9bd9ff;
    --ch-blue-250: #8ed3ff;
    --ch-blue-275: #81cdff;
    --ch-blue-300: #74c7ff;
    --ch-blue-325: #67bcff;
    --ch-blue-350: #59b2ff;
    --ch-blue-375: #4ca7ff;
    --ch-blue-400: #3e9cff;
    --ch-blue-500: #1370ff;
    --ch-blue-600: #005eff;
    --ch-blue-700: #005eff;
    --ch-blue-800: #0054e4;
    --ch-blue-900: #003bb0;
    --ch-blue-950: #001e62;
    --ch-blue: var(--ch-blue-950);

    --preds-yellow-50: #fffaeb;
    --preds-yellow-100: #fff2c6;
    --preds-yellow-200: #ffe388;
    --preds-yellow-300: #ffcf4a;
    --preds-yellow-400: #ffb81c;
    --preds-yellow-500: #f99707;
    --preds-yellow-600: #dd7002;
    --preds-yellow-700: #b74d06;
    --preds-yellow-800: #943a0c;
    --preds-yellow-900: #7a300d;
    --preds-yellow-950: #461802;
    --preds-yellow: var(--preds-yellow-400);

    --wild-green-50: #effaf4;
    --wild-green-100: #d9f2e2;
    --wild-green-200: #b5e5c9;
    --wild-green-300: #84d1a9;
    --wild-green-400: #51b685;
    --wild-green-500: #2f9a6b;
    --wild-green-600: #207b54;
    --wild-green-700: #196345;
    --wild-green-800: #164f38;
    --wild-green-900: #154734;
    --wild-green-950: #0a241b;
    --wild-green: var(--wild-green-900);

    --tw-slate-50: #f8fafc;
    --tw-slate-100: #f1f5f9;
    --tw-slate-200: #e2e8f0;
    --tw-slate-225: #dce3ec;
    --tw-slate-250: #d7dfe9;
    --tw-slate-275: #d1dae5;
    --tw-slate-300: #cbd5e1;
    --tw-slate-325: #bdc9d7;
    --tw-slate-350: #b0bccd;
    --tw-slate-375: #a2b0c2;
    --tw-slate-400: #94a3b8;
    --tw-slate-425: #8897ad;
    --tw-slate-450: #7c8ca2;
    --tw-slate-475: #708096;
    --tw-slate-500: #64748b;
    --tw-slate-600: #475569;
    --tw-slate-700: #334155;
    --tw-slate-800: #1e293b;
    --tw-slate-900: #0f172a;
    --tw-slate-950: #020617;

    --tw-gray-50: #f9fafb;
    --tw-gray-100: #f3f4f6;
    --tw-gray-125: #f0f1f3;
    --tw-gray-150: #eceef1;
    --tw-gray-175: #e9eaee;
    --tw-gray-200: #e5e7eb;
    --tw-gray-225: #e0e3e7;
    --tw-gray-250: #dbdee3;
    --tw-gray-275: #d6dadf;
    --tw-gray-300: #d1d5db;
    --tw-gray-325: #c4c9d0;
    --tw-gray-350: #b7bcc5;
    --tw-gray-375: #a9b0ba;
    --tw-gray-400: #9ca3af;
    --tw-gray-425: #9097a3;
    --tw-gray-450: #848b98;
    --tw-gray-475: #777e8c;
    --tw-gray-500: #6b7280;
    --tw-gray-600: #4b5563;
    --tw-gray-700: #374151;
    --tw-gray-800: #1f2937;
    --tw-gray-900: #111827;
    --tw-gray-950: #030712;

    --tw-red-50: #fef2f2;
    --tw-red-100: #fee2e2;
    --tw-red-200: #fecaca;
    --tw-red-225: #fec1c1;
    --tw-red-250: #fdb8b8;
    --tw-red-275: #fdaeae;
    --tw-red-300: #fca5a5;
    --tw-red-325: #fb9898;
    --tw-red-350: #fa8b8b;
    --tw-red-375: #f97e7e;
    --tw-red-400: #f87171;
    --tw-red-500: #ef4444;
    --tw-red-600: #dc2626;
    --tw-red-700: #b91c1c;
    --tw-red-800: #991b1b;
    --tw-red-900: #7f1d1d;
    --tw-red-950: #450a0a;

    --tw-orange-50: #fff7ed;
    --tw-orange-100: #ffedd5;
    --tw-orange-200: #fed7aa;
    --tw-orange-225: #fed09d;
    --tw-orange-250: #fec98f;
    --tw-orange-275: #fdc182;
    --tw-orange-300: #fdba74;
    --tw-orange-325: #fdb066;
    --tw-orange-350: #fca658;
    --tw-orange-375: #fc9c4a;
    --tw-orange-400: #fb923c;
    --tw-orange-500: #f97316;
    --tw-orange-600: #ea580c;
    --tw-orange-700: #c2410c;
    --tw-orange-800: #9a3412;
    --tw-orange-900: #7c2d12;
    --tw-orange-950: #431407;

    --tw-yellow-50: #fefce8;
    --tw-yellow-100: #fef9c3;
    --tw-yellow-200: #fef08a;
    --tw-yellow-225: #feec79;
    --tw-yellow-250: #fee869;
    --tw-yellow-275: #fde458;
    --tw-yellow-300: #fde047;
    --tw-yellow-325: #fcdb3b;
    --tw-yellow-350: #fcd62e;
    --tw-yellow-375: #fbd122;
    --tw-yellow-400: #facc15;
    --tw-yellow-425: #f6c612;
    --tw-yellow-450: #f2c00f;
    --tw-yellow-475: #eeb90b;
    --tw-yellow-500: #eab308;
    --tw-yellow-600: #ca8a04;
    --tw-yellow-700: #a16207;
    --tw-yellow-800: #854d0e;
    --tw-yellow-900: #713f12;
    --tw-yellow-950: #422006;

    --tw-green-50: #f0fdf4;
    --tw-green-100: #dcfce7;
    --tw-green-200: #bbf7d0;
    --tw-green-225: #aef5c7;
    --tw-green-250: #a1f3be;
    --tw-green-275: #93f1b5;
    --tw-green-300: #86efac;
    --tw-green-325: #77eba1;
    --tw-green-350: #68e796;
    --tw-green-375: #59e28b;
    --tw-green-400: #4ade80;
    --tw-green-500: #22c55e;
    --tw-green-600: #16a34a;
    --tw-green-700: #15803d;
    --tw-green-800: #166534;
    --tw-green-900: #14532d;
    --tw-green-950: #052e16;

    --tw-blue-50: #eff6ff;
    --tw-blue-100: #dbeafe;
    --tw-blue-200: #bfdbfe;
    --tw-blue-225: #b4d6fe;
    --tw-blue-250: #a9d0fe;
    --tw-blue-275: #9ecbfd;
    --tw-blue-300: #93c5fd;
    --tw-blue-325: #86bdfc;
    --tw-blue-350: #7ab5fb;
    --tw-blue-375: #6dadfb;
    --tw-blue-400: #60a5fa;
    --tw-blue-500: #3b82f6;
    --tw-blue-600: #2563eb;
    --tw-blue-700: #1d4ed8;
    --tw-blue-800: #1e40af;
    --tw-blue-900: #1e3a8a;
    --tw-blue-950: #172554;

    --min-page-width: 1080px;

    /* These cannot be used in var() statements in media queries, because that
       feature is not supported yet by CSS. However, they are included here as
       a way to document the rationale, and purpose of each harcoded value.
    */
    --phone-portrait-width-break: 568px; /* Logical pixel height resolution of
                                            the iPhone 5s. */
    --mobile-large-width-break: 992px; /* For landscape phones or portrait
                                          tablets. */

    ::-webkit-scrollbar {
        /* Hide scrollbar for Chrome, Safari and Opera */
        display: none;
    }
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
