.competition_section,
.tournament_season_section {
    display: flex;
}

.competition_section.narrow,
.tournament_season_section.narrow {
    flex-direction: column;
}

.competition_header,
.tournament_season_header {
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    padding: 10px;
    align-items: center;
}

.competition_header {
    width: 250px;
}

.tournament_season_header {
    width: 100px;
}

.competition_section.narrow .competition_header,
.tournament_season_section.narrow .tournament_season_header {
    flex-direction: row;
    justify-content: space-between;
    padding: 0 2px 5px 2px;
    width: 100%;
}

.competition_section.narrow .competition_header {
    flex-direction: row-reverse;
}

.competition_header img {
    width: 60px;
}

.competition_section.narrow .competition_header img {
    width: initial;
    height: 24px;
}

.competition_label,
.tournament_season_label {
    font-size: 16px;
}

.seasons_group,
.competitions_group {
    width: 100%;
}

.season_tile_header,
.season_tile_line {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.season_tile_line:not(:first-child) {
    margin-top: 2px;
}

.season_tile_line:last-child {
    padding-bottom: 2px;
}

.season_tile_gp {
    margin-top: -3px;
}

.season_tile_header {
    justify-content: space-between;
}

.season_tile_header > :not(:first-child) {
    margin-left: 4px;
}

.season_tile_line > :not(:first-child) {
    margin-left: 5px;
}

.season_tile_label {
    color: var(--tw-gray-600);
    font-weight: normal;
    font-size: 12px;
}

.indicators_container {
    display: flex;
}

.indicators_container > :not(:first-child) {
    margin-left: 2px;
}

.pillars_adjustments {
    display: flex;
    align-items: center;
    width: 10px;
}

.games_played {
    font-style: normal;
}

.warning {
    color: var(--tw-orange-500);
}

.subsection_header {
    font-size: 12px;
    margin-top: 12px;
    margin-bottom: -2px;
    font-style: normal;
    font-family: AtlasGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: var(--tw-gray-500);
    opacity: 0.95;
}

.no_data {
    margin-top: 5px;
    margin-bottom: 3px;
    font-size: 12px;
    font-style: normal;
    font-family: AtlasGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
}
