.horizontal_distribution_indicator_container {
    display: flex;
    align-items: flex-end;
    flex-grow: 1;
    position: relative;
    justify-content: flex-end;
}

.horizontal_distribution_indicator {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    margin: 0 6px;
}

.horizontal_distribution_indicator_content {
    width: 100%;
    position: relative;
}

.horizontal_distribution_std_dev_bar {
    background-color: var(--tw-gray-200);
    position: relative;
    height: 10px;
    border-radius: 4px;
    top: 0;
}

.horizontal_distribution {
    position: relative;
    height: 10px;
    border-radius: 4px;
    background-color: var(--tw-slate-400);
}

.inverse {
    background-color: var(--tw-slate-200);
}

.horizontal_distribution_label {
    padding-left: 4px;
    min-width: 32px;
    text-align: left;
}

.horizontal_distribution_avg_label {
    color: var(--tw-gray-400);
    font-size: 10px;
    margin-bottom: 2px;
}

.horizontal_distribution_value {
    width: 8px;
    height: 8px;
    top: 1px;
    border-radius: 4px;
    position: absolute;
}

.horizontal_distribution_data_point {
    width: 4px;
    height: 4px;
    top: 3px;
    border-radius: 2px;
    position: absolute;
    background-color: var(--tw-gray-200);
}

.horizontal_distribution_value.low_tier {
    background-color: var(--ch-red-500);
}
.horizontal_distribution_value.inverse.low_tier {
    background-color: var(--ch-red-300);
}

.horizontal_distribution_value.mid_tier {
    background-color: var(--tw-slate-400);
}
.horizontal_distribution_value.inverse.mid_tier {
    background-color: var(--tw-slate-300);
}

.horizontal_distribution_value.high_tier {
    background-color: var(--ch-blue-500);
}
.horizontal_distribution_value.inverse.high_tier {
    background-color: var(--ch-blue-200);
}

.horizontal_distribution_value.top_tier {
    background-color: var(--tw-green-500);
}
.horizontal_distribution.inverse.top_tier {
    background-color: var(--tw-green-300);
}

.horizontal_distribution_value.elite_tier {
    background-color: var(--tw-yellow-500);
}
.horizontal_distribution_value.inverse.elite_tier {
    background-color: var(--tw-yellow-200);
}

.horizontal_distribution_value.example_tier {
    background-color: var(--tw-gray-500);
}
.horizontal_distribution_value.inverse.example_tier {
    background-color: var(--tw-gray-300);
}

.separator {
    width: 1px;
    height: 100%;
    background-color: var(--tw-gray-400);
    position: absolute;
    left: 50%;
    top: 0;
}
