.board_selector_container {
    display: flex;
    align-items: center;
}

.board_selector_container > :not(:first-child) {
    margin-left: 8px;
}

.group_selector {
    min-width: 148px;
}

.board_option {
    padding: 6px;
}

@media (max-width: 580px) {
    .board_selector_container {
        flex-direction: column;
        align-items: flex-start;
    }
    .board_selector_container > :not(:first-child) {
        margin-top: 8px;
        margin-left: initial;
    }
}
