/* Define a CSS constant for the button size */
:global(:root) {
    --button-size: 24px;
}

.base_button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: var(--button-size);
    width: var(--button-size);
    color: var(--tw-gray-100);
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera, and Safari */
}

.base_button:not(.material) {
    background-color: var(--tw-gray-400);
    border: 1px solid var(--tw-gray-200);
    border-radius: 5px;
    box-shadow: 0 0.5px 0.5px 0px rgba(9, 30, 66, 0.25);
}

.base_button_label {
    height: var(--button-size);
    width: var(--button-size);
    font-family: Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    line-height: calc(var(--button-size) - 2px);
    font-size: var(--button-size);
}

.base_button.material {
    color: var(--tw-slate-400);
}

.base_button.material.selected {
    color: var(--tw-slate-100);
}

.base_button.material.disabled {
    color: var(--tw-slate-200);
}

.material_icons_small {
    font-family: "Material Symbols Outlined";
    font-size: 20px;
}

.base_button.material span {
    transition: transform 0.5s ease;
}

.base_button.material.rotated span {
    transform: rotate(-90deg);
}