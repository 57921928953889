.overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadein 0.5s;
}

.modalWrapper {
    animation: modalAppear 0.5s;
    margin: 10px;
}

.modalContent {
    padding: 10px;
    max-height: 90vh;
    overflow-y: auto;
}

.modalContent.visibleOverflow {
    overflow: visible;
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes modalAppear {
    from {
        transform: translateY(-50%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}
