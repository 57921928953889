.page_content {
    display: flex;
    width: 100%;
}

.page_section {
    width: 100%;
}

.career_summary {
    max-width: 180px;
}

.season_drilldown {
    width: min-content;
    flex-grow: 0;
}

.scouting_reports {
    min-width: 188px;
    height: 562px;
}

.page_section:not(:last-child) {
    margin-right: 6px;
}

/* Adjustments for phone layout (max width of 800px) */
.page_content.narrow {
    flex-flow: column-reverse;
}

.page_section:not(:last-child).narrow {
    margin-right: initial;
}

.career_summary.narrow {
    max-width: initial;
}

.season_drilldown.narrow {
    width: initial;
    flex: 1;
}

.scouting_reports.narrow {
    height: 320px;
    flex: 1;
}
