.org_content_header {
    width: 100%;
    display: flex;
}

.org_content_header_logo {
    object-fit: cover;
    object-position: 50% 50%;
    display: block;
    position: absolute;
    opacity: 0.25;
    margin-top: -10px;
    margin-bottom: -10px;
    width: 40%;
    height: 80px;
    left: -10%;
}

.mobile {
    display: none;
}

.full {
    display: none;
}

.org_content_header_title {
    position: absolute;
    left: 0px;
    width: 100%;
}

.org_content_header.narrow .org_content_header_logo {
    width: 50%;
    height: 50px;
    margin-top: -3px;
    margin-bottom: -3px;
    left: -16%;
}

.org_content_header.narrow .mobile {
    display: initial;
}

.org_content_header.narrow .medium {
    display: none;
}

.org_content_header.wide .org_content_header_logo {
    margin-top: -20px;
    margin-bottom: -20px;
    width: 20%;
    max-width: 300px;
    height: 100px;
    position: initial;
    opacity: 1;
}

.org_content_header.wide_short .org_content_header_logo {
    width: 30%;
    height: 50px;
    margin-top: -3px;
    margin-bottom: -3px;
}

.org_content_header.wide .medium,
.org_content_header.wide_short .medium {
    display: none;
}

.org_content_header.wide .full,
.org_content_header.wide_short .full {
    display: initial;
}

@media print {
    .org_content_header_title {
        color: black;
    }

    .org_content_header_logo {
        object-fit: initial;
        object-position: 50% 50%;
        display: block;
        position: absolute;
        opacity: 1;
        margin-top: 0;
        margin-bottom: 0;
        margin-left: -20px;
        width: auto;
        left: initial;
    }
}
