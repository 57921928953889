.navigation_bar {
    width: 100%;
    display: flex;
    align-items: center;
    margin-left: 10px;
    min-height: 35px;
}

.navigation_setting {
    color: var(--tw-gray-500);
    font-style: normal;
    font-size: 11px;
    font-weight: 700;
    font-family: AtlasGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin-top: 10px;
    display: flex;
    align-items: center;
}

.navigation_setting:not(:first-child) {
    margin-left: 18px;
}

.setting_label {
    font-size: 14px;
    text-align: left;
    margin-right: 8px;
}

.draft_option {
    padding: 4px 6px;
}

.team_option {
    padding: 6px;
}

.team_option {
    display: flex;
    align-items: center;
    width: 120px;
}

.team_logo > img {
    width: 20px;
    height: 20px;
    margin-right: 6px;
}

/* Prop-based responsive class */
.responsive {
    flex-direction: column;
    align-items: flex-start;
}

.responsive .navigation_setting:not(:first-child) {
    margin-left: initial;
}

/* Media query fallback when width prop is not provided */
@media (max-width: 715px) {
    .navigation_bar:not(.responsive) {
        flex-direction: column;
        align-items: flex-start;
    }
    .navigation_bar:not(.responsive) .navigation_setting:not(:first-child) {
        margin-left: initial;
    }
}
