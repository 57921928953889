.back_link_button {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    font-size: 36px;
    font-style: normal;
    position: relative;
    z-index: 3;
}

.dark {
    color: var(--tw-gray-400);
}
