.icon_button {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    padding: 0 1px;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera, and Safari */
}

.icon_button_label {
    font-family: AtlasGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    color: var(--tw-gray-400);
    margin-right: 2px;
}

.selected {
    background-color: var(--ch-blue-200);
}

.selected .icon_button_label {
    color: var(--tw-gray-300);
}
