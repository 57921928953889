.player_header {
    display: grid;
    grid-template-columns: 1fr 1fr auto;
    flex-grow: 0;
    margin-bottom: 18px;
    font-family: AtlasGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    text-align: left;
    font-weight: bold;
    font-size: 14px;
    color: var(--tw-gray-400);
    padding: 0 8px;
    width: 100%;
}

.player_header_element {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.player_name {
    font-size: 24px;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    color: var(--tw-gray-600);
}

.player_performance_view_button {
    padding-left: 8px;
    cursor: pointer;
}

.player_career_trends_button {
    font-size: 16px;
    padding: 0;
    padding-left: 2px;
    cursor: pointer;
}

.player_details {
    display: flex;
    margin-bottom: 6px;
    align-items: center;
}

.player_position_container {
    display: flex;
    align-items: center;
}

.player_position {
    display: flex;
    align-items: center;
    font-size: 20px;
    color: var(--tw-gray-500);
}

.player_laterality {
    position: relative;
    top: -1.5px;
    margin-left: 4px;
}

.player_birth_info {
    display: flex;
    flex-direction: column;
    margin-bottom: 4px;
}

.player_birth_info_element:not(:last-child) {
    margin-bottom: 2px;
}

.player_agency {
    margin-top: 4px;
    font-size: 12px;
    color: var(--tw-gray-350);
}

.player_contract_details {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-right: 30px;
    max-height: 72px;
}

.player_org_logo {
    max-height: 72px;
}

.waivers_pill_container {
    margin-left: 10px;
}

.draft_target_toggle,
img.org_logo {
    width: 72px;
}

.draft_target_toggle {
    margin-top: 4px;
    display: flex;
    padding: 4px 0;
}

.player_header.narrow {
    grid-template-columns: 1fr auto;
    grid-template-rows: auto auto;
}

.player_header.narrow .player_bio {
    grid-column: 1;
    grid-row: 1;
}
.player_header.narrow .season_summary_container {
    grid-column: 1 / span 2; /* Span across the entire width */
    grid-row: 2;
    justify-content: left;
    margin-right: initial;
    margin-top: 18px;
}
.player_header.narrow .player_contract_details {
    grid-column: 1 / span 2; /* Span across the entire width */
    grid-row: 3;
    justify-content: left;
    margin-right: initial;
    margin-top: 18px;
}
.player_header.narrow .player_org_logo {
    grid-column: 2;
    grid-row: 1;
}

.season_summary_container {
    margin-top: 12px;
    display: flex;
    align-items: flex-start;
}

.season_summary_container > :not(:first-child) {
    margin-left: 12px;
}

.man_games_lost_container {
    display: flex;
    align-items: flex-end;
    margin-top: 3px;
}

.man_games_lost_container > :not(:first-child) {
    margin-left: 4px;
}

.man_games_lost_indicator {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.man_games_lost_header_label {
    font-size: 12px;
    color: var(--tw-gray-450);
}

.man_games_lost_indicator_label {
    min-width: 50px;
    font-size: 10px;
    color: var(--tw-gray-350);
}

.man_games_lost_value {
    display: flex;
    align-items: flex-end;
    font-size: 13px;
    color: var(--tw-slate-475);
}

.man_games_lost_label {
    font-size: 11px;
    color: var(--tw-gray-450);
}

.performance_tile {
    display: flex;
}

.performance_tile_column {
    display: flex;
    flex-direction: column;
    margin-right: 6px;
}

.performance_tile_row {
    display: flex;
    height: 18px;
    width: 100%;
    justify-content: space-between;
}
.performance_tile_item {
    display: flex;
}

.performance_tile_row > :not(:first-child) {
    margin-left: 6px;
}
.performance_tile_item > :not(:first-child) {
    margin-left: 2px;
}

.pillar_adjustment_container {
    display: flex;
    align-items: center;
}

.goalie_performance_trad_stats {
    width: 100%;
    justify-content: space-between;
}

.spacer {
    width: 10px;
}
