.version_selector {
    min-width: 160px;
}

.draftboard_selector {
    min-width: 260px;
}

.wide_draftboard_selector {
    min-width: 340px;
}

.board_selector {
    display: flex;
}

.board_option {
    display: flex;
    padding: 4px 6px;
}
