.nav_menu_open_button {
    cursor: pointer;
    font-size: 28px;
    font-style: normal;
    position: relative;
    z-index: 3;
}

.dark {
    color: var(--tw-gray-400);
}

@media print {
    .nav_menu_open_button {
        display: none;
    }
}