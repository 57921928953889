.dropdown_control {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.dropdown_control,
.dropdown_button {
    width: 34px;
}

.option_selector {
    position: relative;
}

.dropdown_control.wide,
.option_selector.wide,
.dropdown_button.wide {
    width: 100%;
}

.dropdown_button {
    display: flex;
    background-color: #fff;
    border: 1px solid #d5d9d9;
    border-radius: 8px;
    box-shadow: rgba(213, 217, 217, 0.5) 0 2px 5px 0;
    box-sizing: border-box;
}

.dropdown_content {
    position: absolute;
    z-index: 1000;
    width: calc(100%);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid #d5d9d9;
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
}

.dropdown_content.multi_column {
    display: flex;
    flex-direction: row;
    width: auto;
    min-width: 100%;
}

.column {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 0;
}

.dropdown_item {
    position: relative;
    cursor: pointer;
    background-color: white;
    display: flex;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.dropdown_item.divider {
    cursor: default;
    color: var(--tw-gray-400);
    border-bottom: 1px solid var(--tw-gray-200);
    pointer-events: none;
    padding-top: 4px;
    text-align: center;
}

.dropdown_item.selected {
    background-color: var(--ch-blue-100);
}

.dropdown_item:not(.divider):hover,
.dropdown_item:not(.divider):focus {
    background-color: var(--ch-blue-50);
    outline: none;
}

.dropdown_button_triangle {
    color: var(--tw-gray-400);
    font-size: 12px;
}
