.summary_title {
    font-size: 16px;
    margin-top: 15px;
    margin-bottom: 2px;
    color: var(--tw-gray-500);
    opacity: 0.85;
    font-style: normal;
    font-family: AtlasGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.injury_summary_table_container {
    padding: 0 12px;
}

.injury_summary_table {
    width: 100%;
    max-width: 1016px;
    margin: 20px auto;
    font-family: AtlasGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-size: 12px;
    font-weight: normal;
}

.player_injury_summary {
    padding: 8px;
    width: 100%;
    display: grid;
    grid-template-columns: 160px 200px 100px 1fr;
    color: var(--tw-gray-700);
    border-radius: 3px;
    background-color: white;
    box-shadow: 0 0.5px 0.5px 0px rgba(9, 30, 66, 0.25);
    margin-bottom: 4px;
}

.player_injury_summary.clickable {
    box-shadow: 0 0.5px 2px 0.5px rgba(9, 30, 66, 0.25);
    margin-top: 4px;
}

.player_injury_summary.clickable:hover {
    box-shadow: 0px 0px 1px 1px rgba(5, 226, 255, 0.25);
    transition: 0.1s;
}

.player_injury_summary.clickable:active {
    background-color: var(--ch-blue-100) !important;
}

.player_injury_summary div {
    text-align: left;
}

.player_injury_summary_player_name {
    font-weight: 700;
}

.player_injury_summary_item_container {
    display: flex;
    align-items: center;
}

.player_injury_summary_player_injury_details {
    justify-content: end;
    margin-right: 10px;
}

.player_injury_summary_player_injury_note {
    color: var(--tw-gray-500);
    line-height: 14px;
}

.player_injury_summary_player_injury_note_owner {
    font-style: italic;
    color: var(--tw-gray-400);
}

.injury_summary_table_container.narrow .player_injury_summary {
    grid-template-columns: initial;
    grid-template-rows: auto;
    flex-direction: column;
}

.injury_summary_table_container.narrow
    .player_injury_summary_item_container:not(:last-child) {
    margin-bottom: 5px;
}

.injury_summary_table_container.narrow
    .player_injury_summary_player_injury_details {
    justify-content: initial;
    margin-right: initial;
}
