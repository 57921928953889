.expandable_section_wrapper {
    overflow: hidden;
    transition: max-height 0.5s ease-out;
    max-height: 0; /* Start collapsed */
}

.expandable_section_content {
    transition: transform 0.5s ease-out;
    transform: translateY(-100%); /* Start hidden above */
    padding-bottom: 6px;
}

.expandable_section_wrapper.collapsing > .expandable_section_content,
.expandable_section_wrapper.expanded > .expandable_section_content,
.expandable_section_wrapper.expanding > .expandable_section_content {
    transform: translateY(0); /* Slide in */
}

.expandable_section_wrapper.fast {
    transition: max-height 0.25s ease-out;
}

.expandable_section_wrapper.fast > .expandable_section_content {
    transition: transform 0.25s ease-out;
}

.expandable_section {
    font-weight: bold;
    color: var(--tw-gray-500);
    margin-top: 6px;
    font-size: 14px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.expandable_section.bordered {
    border-radius: 6px;
    box-shadow: 0 0.5px 2px 0.5px rgba(9, 30, 66, 0.25);
}

.expandable_section_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 6px;
}
