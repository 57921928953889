/* ViewNavigator.module.css */

.container {
    display: flex;
    width: 100%;
    height: 100%;
    transition: all 0.3s ease;
}

/* Layout variations */
.horizontalLayout {
    flex-direction: column;
}

.verticalLayout {
    flex-direction: row;
}

/* Navigation styles */
.nav {
    display: flex;
    background-color: white;
    transition: all 0.3s ease;
}

.horizontalNav {
    flex-direction: row;
    height: 30px;
    border-bottom: 1px solid var(--tw-slate-350);
}

.verticalNav {
    padding: 10px 0;
    flex-direction: column;
    border-right: 1px solid var(--tw-slate-350);
}

.navItem {
    padding: 4px 12px;
    border: none;
    background: none;
    color: white;
    cursor: pointer;
    transition: all 0.3s ease;
    white-space: nowrap;
    text-align: center;
    font-size: 14px;
    font-family: AtlasGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.navItem:hover {
    background-color: var(--ch-blue-300);
}

.selectedNavItem {
    background-color: var(--tw-slate-500);
    color: white;
    font-weight: bold;
}

.ultraWideNavItem {
    font-size: 16px;
}

/* Content area styles */
.content {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.view {
    flex: 1;
}

/* Media queries */
@media screen and (max-width: 768px) {
    .horizontalNav {
        height: 30px;
    }

    .navItem {
        font-size: 12px;
    }

    .verticalNav {
        width: 100px;
    }
}

@media screen and (min-width: 1920px) {
    .horizontalNav {
        height: 50px;
    }
}
