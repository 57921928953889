.horizontal_slider_container {
    position: absolute;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: fit-content;
}

.title {
    color: var(--tw-gray-400);
    font-size: 14px;
    margin-bottom: 2px;
    width: 100%;
}
