.slide_in_content {
    min-height: calc(100dvh - 35px);
}

.player_header_container {
    margin-top: 5px;
}

.status_updates_container {
    margin: 10px 0 24px 0;
}

.status_update_category_container,
.note_container {
    display: flex;
    width: 100%;
    align-items: center;
}

.status_update_category_container.clickable > .status_update_category_content_container {
    border: 1px solid var(--tw-gray-200);
    border-radius: 3px;
    box-shadow: 0 0.5px 0.5px 0px rgba(9, 30, 66, 0.25);
}

.status_update_category_container:not(:last-child),
.note_container:not(:last-child) {
    margin-bottom: 4px;
}

.status_update_category_content_container,
.note_content {
    flex-grow: 1;
}

.status_update_category_controls_container {
    padding-left: 10px;
}

.status_value_option_container {
    padding: 8px;
}

.empty_log {
    font-family: AtlasGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    margin-top: 10px;
    color: var(--tw-gray-400);
}

.post_update_multi_row {
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.post_update_multi_row.warning {
    background-color: var(--tw-yellow-200);
}

.warning_text_container {
    font-size: 11px;
    font-weight: 700;
    max-width: 200px;
    padding: 5px 10px;
    color: var(--tw-gray-400);
    height: 0;
    overflow: hidden;
    transition: height 0.5s ease;
}

.warning_text_container.warning {
    height: 36px;
}

.warning_text_container .warning_text {
    opacity: 0;
    transition: opacity 0.3s ease;
}

.warning_text_container.warning .warning_text {
    opacity: 1;
}

.date_toggle {
    padding: 5px 0;
    display: flex;
    align-items: center;
}

.date_toggle_date {
    margin-left: 8px;
}
.date_toggle_date.hidden {
    display: none;
}

.spacer {
    height: 10px;
}
