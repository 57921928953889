.player_trad_stats {
    font-family: AtlasGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
    display: flex;
    align-items: center;
}

.trad_stat {
    display: flex;
    margin-left: 4px;
    align-items: center;
    color: var(--tw-gray-500);
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    width: 72px;
}

.trad_stat_label {
    margin-right: 4px;
}

.trad_stat.other_tier {
    width: 72px;
}

.trad_stat.goalie_stat {
    width: 178px;
}

.trad_stat.narrow {
    width: 45px;
    font-size: 11px;
}
.trad_stat.goalie_stat.narrow {
    width: 118px;
}

.trad_stat_value {
    width: 100%;
    text-align: left;
}

.label_qualifier {
    font-size: 7px;
    vertical-align: sub;
    margin-right: 2px;
}
