.text_input {
    flex-grow: 1;
    padding: 8px 12px;
    border: none;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    resize: none; /* For textarea */
}

.multiline {
    height: 104px;
}

/* Dark mode styles */
.dark_mode {
    color: var(--tw-slate-300);
    background-color: var(--tw-slate-800);
}

.dark_mode::placeholder {
    color: var(--tw-slate-500);
}

.dark_mode:focus::placeholder {
    color: var(--tw-slate-300);
}

.dark_mode:focus {
    outline: none;
    color: var(--tw-slate-50);
}

/* Light mode styles */
.light_mode {
    color: var(--tw-slate-500);
    background-color: white;
}

.light_mode::placeholder {
    color: var(--tw-slate-300);
}

.light_mode:focus {
    color: var(--tw-slate-600);
    outline: none;
}
