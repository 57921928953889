.metric_top_players {
    width: 100%;
}

.player_ranking {
    color: var(--tw-gray-500);
    font-size: 12px;
    font-style: normal;
    font-family: AtlasGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
    text-align: left;
    padding: 0 4px 0 20px;
    width: 100%;
}

.player_ranking_entry {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.player_ranking_position {
    width: 24px;
}

.player_ranking_team {
    width: 42px;
    text-align: center;
    margin-right: 4px;
}

.player_ranking_name {
    flex-grow: 1;
}

.player_ranking_value {
    width: 42px;
    text-align: right;
}

.player_page_link {
    margin-left: 6px;
    min-width: 24px;
}

.player_ranking_team_logo {
    margin-right: 6px;
}

.player_ranking_team_logo img {
    width: 18px;
    height: 18px;
}
