.main_box {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 3px;
    background: var(--tw-gray-200);
    box-shadow: 0 0.5px 1px 0 rgba(9, 30, 66, 0.25);
    color: var(--tw-gray-600);
    font-weight: bold;
    border-radius: 5px;
    border: 1px solid var(--tw-gray-200);
}

.fill_height {
    height: 100%;
}

@media (max-width: 500px) {
    .clear_on_narrow {
        margin-top: 0;
        margin-bottom: 0;
        box-shadow: none;
        border: none;
    }
}

@media print {
    .main_box:not(.section) {
        box-shadow: none;
        border: none;
    }
}