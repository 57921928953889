.dialog_button {
    background-color: #fff;
    border: 1px solid #d5d9d9;
    border-radius: 8px;
    box-shadow: rgba(213, 217, 217, 0.5) 0 2px 5px 0;
    box-sizing: border-box;
    color: var(--tw-slate-600);
    cursor: pointer;
    display: inline-block;
    font-family: "acumin-pro-condensed", sans-serif;
    font-weight: 700;
    font-style: italic;
    font-size: 15px;
    line-height: 29px;
    padding: 0 10px 0 11px;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    width: 80px;
}

.dialog_button:hover {
    background-color: #f7fafa;
}

.dialog_button:focus {
    border-color: #008296;
    box-shadow: 0px 0px 1px 1px rgba(5, 226, 255, 0.25);
    outline: 0;
}

.dialog_button.disabled {
    color: var(--tw-gray-400);
    background-color: var(--tw-gray-100);
    box-shadow: none;
}

.dialog_button.active {
    color: var(--tw-gray-400);
    background-color: var(--ch-blue-100);
    box-shadow: none;
}

.dialog_button.slim {
    font-style: normal;
    font-size: 11px;
    width: initial;
    font-family: AtlasGrotesk,"Helvetica Neue",Helvetica,Arial,sans-serif;
    padding: 4.5px 8px;
    line-height: initial;
    color: var(--tw-gray-500);
}

.dialog_button:not(:first-child) {
    margin-left: 8px;
}
