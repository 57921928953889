.player_note_container {
    font-family: AtlasGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    color: var(--tw-gray-800);
    text-align: left;
    padding: 10px 10px;
}

.player_note_container:not(:last-child) {
    margin-bottom: 4px;
}

.player_note_title {
    font-weight: bold;
    color: var(--tw-gray-500);
}

.player_note_content {
    display: flex;
    justify-content: space-between;
}

.player_note_content.no_update {
    color: var(--tw-gray-400);
}

.player_note_text {
    font-size: 14px;
    margin: 8px 32px 8px 0;
    display: flex;
    align-items: center;
    position: relative;
}

.player_note_controls_container {
    position: absolute;
    margin-left: 6px;
    display: flex;
    align-items: center;
    top: 50%;
    transform: translateY(-50%) translateX(28px);
    right: 0;
}

.player_note_header:first-child {
    margin-top: 0;
}

.player_note_header {
    font-size: 10px;
    color: var(--tw-slate-400);
    font-weight: 700;
    margin: 6px 12px 6px 0;
    display: flex;
}

.header_element {
    display: flex;
}

.player_note_content.no_update .player_note_text {
    margin: 6px 0;
}

.player_note_owner {
    font-style: italic;
    color: var(--tw-gray-400);
}

.player_status_value {
    display: flex;
    justify-content: flex-end;
}

.header_spacer {
    margin: 0 4px;
}
