.dialog_title {
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 15px;
}

.dialog_container {
    font-family: AtlasGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: var(--tw-gray-500);
    padding: 4px 0;
}

.dialog_row {
    width: 100%;
    min-height: 48px;
    display: flex;
    padding: 5px 3px 5px 0;
}

.dialog_control {
    flex-grow: 1;
    display: flex;
}

.dialog_control_label {
    font-weight: 700;
    width: 82px;
    text-align: right;
    padding: 6px 10px 6px 0;
    line-height: 16px;
}

.single_line .dialog_control_label {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.dialog_control_label_main {
    margin-bottom: -2px;
}

.single_line .dialog_control_label_main {
    margin-bottom: 0;
}

.dialog_control_label_decoration {
    font-weight: normal;
    font-size: 11px;
    font-style: italic;
}

.dialog_control_input {
    display: flex;
    flex-grow: 1;
    align-items: center;
    min-width: 200px;
}

.dialog_buttons {
    width: 100%;
    display: flex;
    margin-top: 10px;
    margin-left: -2px;
}
