.playergrid {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 100%;
}

.playergrid_row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: relative;
}

.playergrid_cell {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    flex: 1;
    margin: 3px;
    min-height: 40px;
    transition: background-color 0.2s ease, border 0.2s ease;
}

.playergrid_cell.dnd_layout {
    border: 1px solid transparent;
}

.playergrid_header > .playergrid_cell {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 16px;
}

.playergrid.tight_layout .playergrid_cell {
    margin: 0px 2px 1px 0px;
}

.playergrid .playergrid_cell:nth-child(1),
.playergrid .playergrid_cell:nth-child(3) {
    margin-right: 20px;
}

.playergrid.tight_layout .playergrid_cell:nth-child(1),
.playergrid.tight_layout .playergrid_cell:nth-child(3) {
    margin-right: 12px;
}

.empty_cell:not(.stack_view) {
    background: var(--tw-gray-250);
}

.droppable_isDraggingOver.empty_cell {
    background: var(--ch-blue-200);
}

.droppable_isDraggingOver:not(.empty_cell) {
    border: 1px solid var(--ch-blue-200); /* Border highlight for cells with players */
}

.playertile_container {
    width: 100%;
}

.playertile_draggable {
    cursor: grab;
}

.row_count_container {
    min-width: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.row_count_container.no_count {
    min-width: 0px;
}

.insert_row_control {
    position: absolute;
    top: -8px;
    width: calc(100% - 16px);
    z-index: 1;
    display: flex;
    align-items: center;
    opacity: 0; /* initially invisible */
    transition: opacity 0.3s ease-in-out;
}

.insert_row_control.visible {
    opacity: 1; /* visible when hovered or clicked */
}

.insert_row_control > * {
    background-color: var(--ch-blue-400);
}

.insert_row_control.highlight > * {
    background-color: var(--tw-yellow-300);
}

.insert_row_spacer {
    border-radius: 2px 0 0 2px;
    height: 4px;
    flex-grow: 1;
}

.insert_row_button {
    position: relative;
    left: -1px;
    border-radius: 8px;
    height: 16px;
    width: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.insert_row_button_label {
    position: relative;
    top: -3px;
    font-size: 16px;
    font-weight: bold;
    color: white;
}

.remove_row_control {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    opacity: 0; /* initially invisible */
    transition: opacity 0.3s ease-in-out;
}

.remove_row_control.visible {
    opacity: 1; /* visible when hovered or clicked */
}

.remove_row_control > * {
    background-color: var(--ch-red-400);
}

.remove_row_control.highlight > * {
    background-color: var(--tw-yellow-300);
}

.remove_row_button {
    position: relative;
    right: 6px;
    border-radius: 8px;
    height: 16px;
    width: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.remove_row_button_label {
    position: relative;
    top: -1px;
    font-size: 14px;
    font-style: normal;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
}

@media print {
    .playergrid_row {
        break-inside: avoid; /* Avoids splitting within the element */
    }
    .playergrid_cell.dnd_layout.empty_cell {
        border: 1px solid var(--tw-gray-300);
    }
}
