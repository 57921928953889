.playerboard {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
    background: var(--tw-gray-200);
}

h2 {
    color: var(--tw-gray-600);
}

.board_controls {
    width: 100px;
    padding: 50px 7px;
    display: flex;
    flex-direction: column;
    font-size: 14px;
}

.board_controls_label {
    text-align: center;
    font-size: 12px;
    font-style: normal;
    color: var(--tw-gray-500);
    font-family: AtlasGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin-bottom: 10px;
}

.setting_label {
    margin-top: 4px;
    margin-bottom: 2px;
    font-size: 14px;
}

.narrow_selector {
    display: none;
    font-size: 11px;
    font-style: normal;
    color: var(--tw-gray-500);
    font-family: AtlasGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.page_class_option {
    padding: 6px;
}

.short_label {
    display: none;
}

@media print {
    .board_controls {
        display: none;
    }
}

@media (max-width: 1005px) {
    .playerboard {
        flex-direction: column;
    }
    .board_controls {
        flex-direction: row;
        align-items: center;
        padding: 5px 10px 0px 10px;
        width: auto;
        justify-content: flex-start;
        text-align: left;
    }
    .board_controls_label {
        margin-bottom: 0;
    }
    .board_controls > div {
        text-align: left;
        width: auto;
        margin-right: 10px;
    }
    .wide_selector {
        display: none;
    }
    .narrow_selector {
        display: initial;
    }
}

@media (max-width: 250px) {
    .full_label {
        display: none;
    }
    .short_label {
        display: initial;
    }
}
