.playertile {
    width: 100%;
    text-align: left;
    padding: 5px 5px 0px 5px;
    font-size: 12px;
    font-family: AtlasGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    border-radius: 3px;
    box-shadow: 0 1px 2px 0 rgba(9, 30, 66, 0.25);
    margin-bottom: 1px;
    transition: 0.5s;
    position: relative;
    user-select: none;
}

.scouting_projection,
.decorator_pill {
    font-size: 9px;
    background-color: var(--tw-gray-400);
    color: white;
    border-radius: 3px;
    text-align: center;
    height: 15px;
    box-shadow: 1px 1px 2px 1px var(--tw-gray-300);
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: bold;
}

.projected_free_agency_pill:not(.light_label) {
    color: var(--tw-gray-500);
}

.projected_free_agency_pill,
.scouting_projection {
    min-width: 30px;
}

.contract_clause_pill {
    width: 36px;
}

.nmc_clause {
    background: var(--ch-red-500);
}

.ntc_clause {
    background: var(--tw-orange-400);
}

.m_ntc_clause {
    background: var(--tw-yellow-400);
}

.elc_clause {
    background: var(--ch-blue-400);
    width: 28px;
}

.perf_bonus_clause {
    background: var(--ch-blue-400);
    width: 11px;
}

.scouting_projection.inverse {
    box-shadow: initial;
}

.playertile.lefty::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 3px;
    height: 100%;
    background-color: var(--tw-slate-350);
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.playertile.righty::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 3px;
    height: 100%;
    background-color: var(--tw-slate-350);
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.projected_ufa {
    background: var(--tw-green-100);
}

.projected_rfa {
    background: var(--ch-blue-100);
}

.ten_2c {
    background: var(--tw-yellow-100);
}

.pto {
    background: var(--tw-orange-100);
}

.ato {
    background: var(--tw-orange-100);
}

.unsigned {
    background: var(--tw-gray-300);
}

.ahl_contract {
    background: var(--tw-orange-100);
}

.ahl_contract.unsigned,
.pto.unsigned,
.ato.unsigned {
    background: var(--tw-orange-200);
}

.tilenameline {
    position: relative;
    display: flex;
    margin-bottom: -3px;
    transition: 0.25s ease-in-out;
}

.playerstatsline {
    position: relative;
    display: flex;
    height: 24px;
    padding-bottom: 3px;
    align-items: flex-end;
}

.transition_hide {
    opacity: 0;
    transition: 0.25s ease-in-out;
}

.tileline_decorators_section {
    display: flex;
    margin-right: 1px;
    transition: 0.25s ease-in-out;
}

.tileline_decorators_section > :not(:first-child) {
    margin-left: 2px;
}

/* Additional styles for tiles that are clickable. */
.playertile.clickable:hover {
    box-shadow: 0px 0px 1px 1px rgba(5, 226, 255, 0.25);
    transition: 0.1s;
}

.playertile.clickable:active,
.playertile.selected {
    background-color: var(--ch-blue-100) !important;
}

.basetile {
    background: #fff;
}

.draft_target_indicator,
.trend_indicator,
.playerpillars {
    display: flex;
}

.draft_target_indicator span {
    position: relative;
    top: -1px;
    font-size: 13px;
    color: var(--ch-blue-400);
    font-variation-settings: "FILL" 1;
}

.inverse .draft_target_indicator span {
    color: var(--tw-gray-200);
}

.playerpillars > img,
.pillars_example > img {
    width: 10px;
    height: 10px;
}

.playerpillars > img,
.pillars_example > img {
    -webkit-filter: drop-shadow(1px 1px 1px var(--tw-gray-500))
        drop-shadow(-1px -1px 1px var(--tw-gray-500));
    filter: drop-shadow(1px 1px 1px var(--tw-gray-500))
        drop-shadow(-1px -1px 1px var(--tw-gray-500));
}

.tile_main_name {
    color: var(--tw-gray-600);
    font-weight: normal;
    font-size: 13px;
    white-space: nowrap; /* Prevents text from wrapping */
    overflow: hidden; /* Hides overflow content */
    text-overflow: ellipsis; /* Adds the ... at the end */
}

.playertile.inverse .tile_main_name {
    color: var(--tw-gray-50);
    font-weight: bold;
}

.playertile .tileline_main_section {
    flex: 1 0;
}

.playerstatsline .tileline_main_section {
    display: flex;
    align-items: center;
}

.playertile.hidden {
    display: none;
}

.playerage {
    color: var(--tw-gray-500);
    font-size: 10px;
}

.playertile.inverse .playerage {
    color: var(--tw-gray-300);
}

.namedecoration {
    font-style: italic;
    color: var(--tw-gray-500);
    font-size: 10px;
}

.namedecoration.light {
    font-style: italic;
    color: var(--tw-orange-500);
    font-size: 10px;
}

.playertile.inverse .namedecoration {
    color: var(--tw-gray-300);
}

.playertile.inverse .namedecoration.light {
    color: var(--tw-orange-300);
}

.owningrights {
    opacity: 0;
    transition: 0.35s;
}

.owningrights.active {
    opacity: 0.8;
}

.owningrights_bg {
    position: absolute; /* Overlay div positioned relative to parent */
    top: 0; /* Align with the top of the parent */
    left: 0; /* Align with the left of the parent */
    width: 100%; /* Take the full width of the parent */
    height: 100%; /* Take the full height of the parent */
    z-index: 0; /* Make sure the overlay appears on top of the image */
    opacity: 1;
    transition: 0.3s;
    border-radius: 3px;
}

img.owningrights {
    position: absolute;
    top: 0; /* Align with the top of the parent */
    right: 5.5%;
    object-fit: cover;
    object-position: 50% 50%;
    width: 35%;
    height: 100%;
    opacity: 0.83;
    z-index: 0;
    transition: 0.3s;
}

.waivers_exemption {
    background-color: var(--tw-green-500);
    color: white;
    border-radius: 3px;
    font-size: 9px;
    padding: 1px 1px;
    width: 11px;
    height: 15px;
    box-shadow: 1px 1px 2px 1px var(--tw-gray-300);
}

.flat {
    box-shadow: none;
}

.contract_length {
    color: var(--tw-slate-500);
}

@media (max-width: 1475px) {
    img.owningrights:not(.force_small) {
        opacity: 0.7;
        right: 2%;
    }
}

@media (max-width: 1375px) {
    img.owningrights:not(.force_small) {
        opacity: 0.3;
        width: 80%;
        right: 10%;
    }
}
img.owningrights.force_small {
    opacity: 0.3;
    width: 80%;
    right: 10%;
}

img.owningrights.faded {
    opacity: 0.1;
}

.ranking_indicator {
    margin-top: 4px;
    margin-left: -1px;
    color: var(--tw-slate-400);
    font-size: 10px;
    font-weight: bold;
}

.ranking_indicator.inverse {
    color: var(--tw-gray-300);
}

.ranking_indicator.example {
    color: var(--tw-slate-500);
}

.performance_decorators {
    display: flex;
}

.performance_decorators > :not(:last-child) {
    margin-right: 3px;
}

.skater_performance_group,
.indicator_group {
    display: flex;
}

.indicator_group:not(:first-child) {
    margin-left: 6px;
}

.indicator_group > :not(:last-child) {
    margin-right: 3px;
}
.indicator_group.tight > :not(:last-child) {
    margin-right: 2px;
}
.indicator_group.wide > :not(:last-child) {
    margin-right: 6px;
}

.goalie_performance_section {
    display: flex;
    position: relative;
    top: 1px;
}

.gp_indicator {
    width: 32px;
    text-align: left;
}

.skaters_points_indicators {
    display: flex;
    justify-content: center;
}

.skaters_points_indicators.spaced {
    width: 80px;
}

.plusminus_indicator {
    width: 32px;
    display: flex;
    justify-content: center;
}

.pim_indicator {
    width: 40px;
    display: flex;
}

@media print {
    .playertile,
    .scouting_projection,
    .decorator_pill {
        box-shadow: none;
        border: 1px solid var(--tw-gray-300);
    }
    .playerage,
    .namedecoration,
    .trend_indicator {
        display: none;
    }
}
