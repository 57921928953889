.button_indicators,
.indicators_container,
.breadcrumb_indicators {
    display: flex;
    margin-right: 18px;
}

.breadcrumb_indicators {
    margin-top: 8px;
}

.performance_content {
    display: flex;
    flex-direction: column;
}
.performance_content.wide {
    flex-direction: row;
}

.performance_content_section {
    min-width: 370px;
}
.performance_content.wide .performance_content_section {
    max-width: 370px;
}

.standalone_indicator {
    width: 72px;
    height: 38px;
    display: flex;
    flex-direction: column;
    font-size: 15px;
    font-family: AtlasGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    border-radius: 6px;
    justify-content: center;
    color: var(--tw-gray-500);
}

.standalone_indicator:not(:first-child) {
    margin: 0 15px;
}

.standalone_indicator_label {
    font-size: 10.5px;
    margin-bottom: 2px;
}

.metric_breakdown_content {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
    align-items: start;
    grid-gap: 4px;
}

.vertical_bar_container {
    position: relative;
    display: flex;
    height: 200px;
    font-family: AtlasGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 600;
    text-align: left;
    font-size: 14px;
}

.performance_content.wide .vertical_bar_container {
    height: 284px;
}

.metric_breakdown_content .metric_container {
    height: 100%;
    display: grid; /* changed from flex to grid */
    grid-template-rows: 1fr auto; /* adjust this to your needs */
    max-width: 85px;
    padding: 3px 6px 0 6px;
    border-radius: 6px;
}

.button_indicators,
.metric_breakdown,
.metric_trend {
    margin: 12px 10px 0 10px;
}

.metric_breakdown {
    margin-top: 25px;
    margin-bottom: 24px;
}

.single_indicator_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: 0.4s;
}

.breadcrumb_indicator_container {
    margin-right: 4px;
    padding: 6px 3px;
    border-radius: 6px;
    max-width: 85px;
}

.clickable {
    box-shadow: 0 0.5px 2px 0.5px rgba(9, 30, 66, 0.25);
}

.clickable:hover {
    box-shadow: 0px 0px 1px 1px rgba(5, 226, 255, 0.25);
    transition: 0.1s;
}

.clickable:active,
.highlight {
    background-color: var(--ch-blue-100) !important;
}

.base_indicators_header,
.single_indicator_header,
.metric_breakdown_header,
.metric_header,
.metric_trend_header,
.metric_top_ten_header {
    font-family: AtlasGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-size: 14px;
    color: var(--tw-gray-600);
}

.base_indicators_header,
.single_indicator_header {
    margin-bottom: 6px;
}

.base_indicators_header,
.metric_trend_header,
.metric_breakdown_header {
    color: var(--tw-gray-500);
}

.metric_breakdown_header {
    margin-bottom: 10px;
}

.metric_header,
.metric_trend_header {
    margin-bottom: 8px;
}

.usage_indicator_header {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 80px;
    max-width: 130px;
    text-align: center;
    font-size: 12px;
}

.breadcrumb_indicator_header {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 80px;
    max-width: 130px;
    min-height: 35px;
    text-align: center;
    font-size: 12px;
}

.metric_header {
    margin-top: 8px;
    font-size: 12px;
}

.base_indicators_header,
.metric_breakdown_header,
.metric_trend_header {
    text-align: left;
    margin-left: 3px;
}

.metric_trend_header {
    margin-bottom: 12px;
}

.metric_top_ten_header {
    font-size: 12px;
    color: var(--tw-gray-500);
}

.transition_indicator {
    transition: opacity 0.2s, background-color 0.2s;
}

.transition_indicator.transitioning {
    opacity: 0;
    transition: background-color 0;
}

.no_data {
    color: var(--tw-gray-600);
    font-size: 12px;
    font-style: normal;
    font-family: AtlasGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
    width: 200px;
    margin: 0 auto;
}
