.container {
    display: inline-flex;
    align-items: center;
    gap: 8px;
}

.container.vertical {
    flex-direction: column;
}

.button {
    display: flex;
    align-items: center;
    gap: 4px;
}

.button.vertical {
    flex-direction: column;
}

/* Admin-specific styles */
.admin {
    cursor: pointer;
    border-radius: 8px;
    border: 1px solid var(--tw-slate-200);
    gap: 8px;
    padding: 12px;
    background-color: white;
    transition: all 0.2s ease;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.admin:hover:not(.selected) {
    background-color: var(--tw-slate-50);
}

.admin.selected {
    background-color: var(--ch-blue-100);
}

/* Icon container styles */
.iconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.admin .iconContainer {
    height: 32px;
    width: 32px;
}

.iconContainer:not(.admin) {
    height: 24px;
    width: 24px;
}

/* Label styles */
.label {
    font-family: AtlasGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    color: var(--ch-blue-350);
    text-align: center;
}

.admin .label {
    color: var(--tw-gray-350);
}

.admin.selected .label {
    color: var(--ch-blue-400);
}

/* Material icon styles */
.materialIcon {
    font-family: "Material Symbols Outlined";
    font-size: 20px;
    transition: color 0.2s ease;
    color: var(--tw-gray-350);
}

.admin .materialIcon {
    font-size: 24px;
    color: var(--tw-gray-300);
}

.selected .materialIcon {
    color: var(--ch-blue-400);
    font-variation-settings: "FILL" 1;
}

.admin.selected .materialIcon {
    color: var(--ch-blue-400);
}

/* User selection prevention */
.container {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
