.radio_bar {
    display: inline-flex;
    overflow: hidden;
    border: 1px solid #d5d9d9;
    border-radius: 8px;
    box-shadow: rgba(213, 217, 217, 0.5) 0 2px 5px 0;
    box-sizing: border-box;
}

.vertical {
    flex-direction: column;
}

.radio_button {
    border: none;
    background-color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
}

.radio_button:not(:last-child) {
    border-right: 1px solid #d5d9d9;
}

.vertical .radio_button:not(:last-child) {
    border-right: none;
    border-bottom: 1px solid #d5d9d9;
}

.radio_button.selected {
    background-color: var(--ch-blue-100);
}

.radio_button:hover:not(.touch):not(.selected) {
    background-color: var(--ch-blue-50);
}

.radio_button:focus {
    outline: none;
    box-shadow: 0 0 0 2px var(--ch-blue-200);
}

.radio_button.first {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.radio_button.last {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.vertical .radio_button.first {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 0;
}

.vertical .radio_button.last {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top-right-radius: 0;
}

/* Ensure the content of each button determines its width */
.radio_button > * {
    width: 100%;
}
