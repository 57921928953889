.fader {
    height: 100%;
    width: 100%;
    position: fixed;
    opacity: 0;
    background-color: black;
    pointer-events: none;
    transition: 0.3s;
    z-index: 2;
}

.faded {
    opacity: 0.6;
    pointer-events: auto;
}
