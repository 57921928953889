.position_chart_player_impact {
    font-family: AtlasGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 600;
    text-align: left;
    font-size: 14px;
    position: relative;
    min-height: 15px;
    flex-grow: 1;
}
