.no_access {
    font-size: 12px;
    font-style: normal;
    font-family: AtlasGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: var(--tw-gray-600);
    margin-top: 20px;
    margin-bottom: 3px;
}

.content_container {
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    position: relative;
    min-height: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.mode_selection {
    display: flex;
    gap: 40px;
    justify-content: center;
    width: 100%;
}

.mode_button {
    background-color: var(--ch-blue-100);
    border: none;
    border-radius: 12px;
    padding: 30px 50px;
    font-size: 24px;
    font-weight: bold;
    color: var(--tw-gray-700);
    cursor: pointer;
    transition: background-color 0.2s;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.mode_button:hover {
    background-color: var(--ch-blue-200);
}

.question_container {
    width: 100%;
    text-align: center;
    margin-bottom: 40px;
    position: relative;
}

.question_text {
    font-size: 42px;
    font-weight: bold;
    color: var(--tw-slate-500);
    margin-bottom: 30px;
}

.options_container {
    display: flex;
    gap: 16px;
    justify-content: center;
}

.scale_label {
    width: 100px;
    color: var(--tw-slate-500);
    font-size: 20px;
    display: flex;
    align-items: center;
}

.scale_label.scale_label_left {
    text-align: right;
    justify-content: flex-end;
}

.scale_label.scale_label_right {
    text-align: left;
}

.option_button {
    padding: 20px 36px;
    font-size: 52px;
    font-weight: bold;
    font-style: normal;
    font-family: AtlasGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: var(--tw-slate-500);
}

.thank_you {
    font-size: 36px;
    font-weight: bold;
    color: var(--ch-blue);
}

.reset_button {
    position: absolute;
    bottom: 0px;
    right: 60px;
    background-color: var(--tw-gray-200);
    border: none;
    border-radius: 8px;
    padding: 12px 24px;
    font-size: 16px;
    font-weight: bold;
    color: var(--tw-gray-500);
    cursor: pointer;
    font-family: AtlasGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
    transition: background-color 0.2s;
}

.reset_button:hover {
    background-color: var(--tw-gray-300);
}

.back_button_container {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 3;
}

.back_button {
    color: var(--tw-gray-600);
    cursor: pointer;
    display: flex;
    align-items: center;
}

.back_button:hover {
    color: var(--tw-gray-800);
}
