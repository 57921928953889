.pre_scout_header {
    font-family: AtlasGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 13px;
    font-style: normal;
    margin: 10px;
    color: var(--tw-slate-500);
}

.prescout_report_container {
    width: 100%;
    max-width: 700px;
}

.pre_scout_metric {
    font-size: 12px;
    font-family: AtlasGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 1px 2px 0 rgba(9, 30, 66, 0.25);
    margin-bottom: 3px;
    transition: 0.5s;
    position: relative;
    user-select: none;
    display: flex;
    align-items: center;
}

.pre_scout_metric_header {
    text-align: left;
    padding: 10px;
}

.pre_scout_metric_help_button {
    min-width: 14px;
    width: 14px;
    height: 14px;
    margin-right: 8px;
    border-radius: 7px;
    color: var(--tw-gray-50);
    box-shadow: 0 1px 2px 0 rgba(9, 30, 66, 0.25);
    background-color: var(--ch-blue-400);
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Arial, sans-serif;
}

.pre_scout_metric_content {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
}

.pre_scout_metric_opponent {
    width: 60px;
    border-radius: 6px;
    padding: 5px;
    margin: 6px 6px 6px 0;
    color: var(--tw-gray-50);
    position: relative;
}

.pre_scout_metric_tile_content {
    position: relative;
}

.pre_scout_metric_opponent_name {
    font-size: 10px;
    color: var(--tw-gray-50);
}

.pre_scout_metric_game_date {
    font-size: 14px;
}

.best_opponent {
    background-color: var(--tw-yellow-500);
}

.second_best_opponent {
    background-color: var(--tw-green-500);
}

.third_best_opponent {
    background-color: var(--ch-blue-500);
}

.spacer {
    height: 70px;
}
