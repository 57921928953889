.position_chart_player_availability {
    display: flex;
    justify-content: flex-end;
}

.no_access {
    font-size: 12px;
    font-style: normal;
    font-family: AtlasGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: var(--tw-gray-600);
    margin-top: 20px;
    margin-bottom: 3px;
}

.player_indicators_container {
    display: flex;
    align-items: center;
}

.position_chart_player_warning_updates {
    display: flex;
    margin: 0 8px 0 3px;
}

.player_warning_update:not(:first-child) {
    margin-left: 6px;
}
