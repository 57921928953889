.legend_title {
    font-size: 18px;
    margin-bottom: 5px;
    color: var(--tw-gray-400);
}

.legend_container {
    display: flex;
}

.legend_section {
    font-family: AtlasGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 15px;
    font-style: normal;
    margin: 2px;
    color: var(--tw-slate-500);
    max-width: 400px;
}

.legend_section_title {
    margin-bottom: 10px;
}

.legend_section_title:not(:first-child) {
    margin-top: 24px;
}

.legend_item {
    display: flex;
    margin-bottom: 8px;
    align-items: center;
}

.legend_section_description,
.legend_item_description {
    font-size: 13px;
    font-weight: normal;
    color: var(--tw-gray-500);
}

.legend_section_description {
    text-align: left;
    margin-bottom: 14px;
}

.legend_item_description {
    text-align: left;
}

.legend_symbol {
    margin: 0 20px;
    min-width: 40px;
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;
}

.weighed_average {
    margin: 5px 10px;
    min-width: 95px;
}

.legend_symbol img {
    position: relative;
    top: -2px;
    width: 15px;
    filter: drop-shadow(1px 1px 1px var(--tw-gray-500))
        drop-shadow(-1px -1px 1px var(--tw-gray-500));
}

.legend_items_separator {
    height: 12px;
}

h4 {
    margin-bottom: 12px;
}