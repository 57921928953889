.slide_in_content {
    min-height: calc(100dvh - 35px);
}

.development_priorities_section_container {
    position: relative;
}

.add_development_priorities_controls_container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 8px;
    padding-right: 4px;
    align-items: center;
}

.player_header_container {
    margin-top: 5px;
}

.development_priorities_container {
    margin: 10px 0 24px 0;
}

.development_priority_container,
.note_container {
    display: flex;
    width: 100%;
    align-items: center;
}

.development_priority_container:not(:last-child),
.note_container:not(:last-child) {
    margin-bottom: 4px;
}

.development_priority_content_container,
.note_content {
    flex-grow: 1;
}

.development_priority_controls_container {
    padding-left: 10px;
}

.no_content_text {
    font-family: AtlasGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    margin-top: 10px;
    color: var(--tw-gray-400);
}

.development_session_container {
    font-family: AtlasGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    color: var(--tw-gray-800);
    text-align: left;
    padding: 10px 10px;
}

.development_session_priority {
    font-style: italic;
    color: var(--tw-gray-400);
    font-weight: bold;
}
