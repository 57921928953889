.page_class_option {
    padding: 6px;
}

.toggle_child {
    margin-left: 8px;
    margin-right: 4px;
}

.view_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: opacity 0.15s ease;
}

.view_container.hidden {
    opacity: 0;
}

.roster_edit_options {
    display: flex;
    align-items: center;
}

.roster_edit_options > :not(:first-child) {
    margin-left: 12px;
}

.roster_view_toggle {
    display: flex;
    align-items: center;
}
